import {
    Badge,
    Box,
    Card,
    CardBody,
    Center,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    Portal,
    Tag,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {PiChartLineUpBold, PiTelegramLogo} from "react-icons/pi";
import {ExternalLinkIcon, Icon, TriangleDownIcon, TriangleUpIcon} from "@chakra-ui/icons";
import {FaSackDollar, FaUserPlus, FaXTwitter} from "react-icons/fa6";
import {BsGlobe, BsHeartPulseFill, BsStopwatchFill} from "react-icons/bs";
import React, {useState} from "react";
import TokenRowCollapse from "./TokenRowCollapse";
import {TokenData} from "../../../../interfaces/datatypes";
import {MdCurrencyExchange} from "react-icons/md";
import {openTraderInfoModal, setSelectedTokenAddress} from "../../../../reducers/timelineSlice";
import {useDispatch} from "react-redux";

interface TokenRowProps {
    tokenData: TokenData
}

const TokenRow: React.FC<TokenRowProps> = ({tokenData}) => {

    const dispatch = useDispatch();

    const imageModal = useDisclosure();
    const rowCollapse = useDisclosure();

    const [styles, setStyles] = useState({
        bg: 'gray.600',
        shadow: 'dark-lg',
    });

    const handleMouseOver = () => {
        setStyles({
            bg: 'gray.600',
            shadow: 'sm',
        });
    };

    const handleMouseOut = () => {
        setStyles({
            bg: 'gray.600',
            shadow: 'dark-lg',
        });
    };

    const getColor = (score: number) => {
        const getLetterGrade = (totalScore: number) => {
            return totalScore > 80 ? "S"
                : totalScore >= 70 ? "A"
                    : totalScore >= 60 ? "B"
                        : totalScore >= 50 ? "C"
                            : totalScore >= 40 ? "D"
                                : totalScore >= 30 ? "E"
                                    : totalScore >= 20 ? "F"
                                        : "L";
        };

        const gradeColorMap: { [key: string]: string } = {
            "S": "#C149D6",  // Magenta
            "A": "#69B34C",
            "B": "#0C6B37",
            "C": "#094A25",  // Green
            "D": "#FF9324",
            "E": "#EB442C",
            "F": "#BC2023",
            "L": "#661010"  // Red
        };

        const letterGrade = getLetterGrade(score);
        return gradeColorMap[letterGrade];
    };

    return (
        <>
            {/*<ImageModal username={session?.username} apiKey={session?.apiKey}*/}
            {/*            contractAddress={tokenData.contractAddress} imageUrl={tokenData.imageUrl}*/}
            {/*            isOpen={imageModal.isOpen} onClose={imageModal.onClose}/>*/}

            <GridItem>
                <Card mb={0.5} w="99%"
                      {...styles}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}>
                    <CardBody p={"0"}>
                        <Grid gap={1} alignItems="center"
                              gridTemplateColumns={'9fr 20fr 10fr 10fr 10fr 10fr 10fr 30fr 10fr'} h={"100%"}
                              w={"100%"}>
                            <GridItem onClick={rowCollapse.onToggle}>
                                <Tag
                                    bgGradient={`linear(to-r, ${getColor(tokenData.totalScore)} 0%, ${getColor(tokenData.totalScore)} 10%, gray.600 100%)`}
                                    color={"whitesmoke"}
                                    size={'lg'}
                                    w={"75px"}>
                                    <Center>
                                        <Text fontSize="sm" as={"b"} p={0}>
                                            {tokenData.letterGrade} {tokenData.totalScore.toFixed(0)}
                                        </Text>
                                    </Center>
                                </Tag>
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                <HStack gap={1}>
                                    <Text fontSize="xs" mr="auto"
                                          color={"whitesmoke"}>{tokenData.tokenSymbol.substring(0, 10)}</Text>
                                    {tokenData.isERC404 &&
                                        <Badge mx={1} fontSize="2xs" borderRadius={"lg"} variant='subtle'
                                               colorScheme='brand.orange'>ERC404</Badge>}
                                </HStack>
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                {tokenData.firstBlockWithSwaps && tokenData.lastHeartbeatBlock &&
                                    <Tag size='sm' bg={"#2e3540"} variant='solid' borderRadius='full'
                                         h={"100%"}
                                         w={"100%"}>
                                        <HStack gap={1}>
                                            <Icon as={BsStopwatchFill}
                                                  my={1}
                                                  style={{color: "whitesmoke", fontSize: '16px'}}/>
                                            <Text>
                                                {tokenData.tokenAgeText?.slice(0, -4)}
                                            </Text>
                                        </HStack>
                                    </Tag>
                                }
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                {tokenData.mostRecentBlockWithSwaps && tokenData.lastHeartbeatBlock &&
                                    <Tag size='sm' bg={"#2e3540"} variant='solid' borderRadius='full'
                                         h={"100%"}
                                         w={"100%"}>
                                        <HStack gap={1}>
                                            <Icon as={BsHeartPulseFill}
                                                  my={1}
                                                  style={{color: "whitesmoke", fontSize: '16px'}}/>
                                            <Text>
                                                {tokenData.activityTimeText?.slice(0, -4)}
                                            </Text>
                                        </HStack>
                                    </Tag>
                                }
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                {tokenData.topAnonCount != null && tokenData.topNamedCount != null &&
                                    <Tag _hover={{
                                        bg: "gray.600", // Example: darken the background on hover
                                        cursor: "pointer", // Change cursor to indicate it's clickable
                                        boxShadow: "0 0 5px white", // Optional: add a shadow or other visual cue
                                    }}
                                         size='sm' bg={"#2e3540"} variant='solid' borderRadius='full'
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             dispatch(openTraderInfoModal({
                                                 topTraderInfo: tokenData.topTraderInfos ?? [],
                                                 tokenAddress: tokenData.contractAddress,
                                                 tokenSymbol: tokenData.tokenSymbol
                                             }));
                                         }}
                                         h={"100%"}
                                         w={"100%"}>
                                        <HStack gap={1}>
                                            <Icon as={FaUserPlus}
                                                  my={1}
                                                  style={{color: "whitesmoke", fontSize: '16px'}}/>
                                            <Text>
                                                {tokenData.topTraderCount} / {tokenData.totalTopTraderCount}
                                            </Text>
                                        </HStack>
                                    </Tag>
                                }
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                {tokenData.marketCapText != null &&
                                    <Tag size='sm' bg={"#2e3540"} variant='solid' borderRadius='full'
                                         h={"100%"}
                                         w={"100%"}>
                                        <HStack gap={1}>
                                            <Icon as={FaSackDollar}
                                                  my={1}
                                                  style={{color: "whitesmoke", fontSize: '16px'}}/>
                                            <Text>
                                                {tokenData.marketCapText}
                                            </Text>
                                        </HStack>
                                    </Tag>
                                }
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                {tokenData.previousTotalScore &&
                                    <Tag size='sm' bg={"#2e3540"} variant='solid' borderRadius='full'
                                         h={"100%"}
                                         w={"100%"}>
                                        <HStack gap={1}>
                                            {tokenData.totalScore - tokenData.previousTotalScore >= 0 &&
                                                <TriangleUpIcon
                                                    my={1}
                                                    color={"green.600"}
                                                    fontSize={'16px'}
                                                />}
                                            {tokenData.totalScore - tokenData.previousTotalScore < 0 &&
                                                <TriangleDownIcon
                                                    my={1}
                                                    color={"red.600"}
                                                    fontSize={'16px'}
                                                />}
                                            <Text>
                                                {(tokenData.totalScore - tokenData.previousTotalScore).toFixed(2)}
                                            </Text>
                                        </HStack>
                                    </Tag>
                                }
                            </GridItem>
                            <GridItem onClick={rowCollapse.onToggle}>
                                <HStack>
                                    <Badge variant={tokenData.isCodeVerified ? 'solid' : 'outline'}
                                           colorScheme='brand.green'>
                                        Verified
                                    </Badge>
                                    <Badge variant={tokenData.isTradingOpen ? 'solid' : 'outline'}
                                           colorScheme='brand.green'>
                                        Open
                                    </Badge>
                                    <Badge variant={tokenData.isLiquidityLocked ? 'solid' : 'outline'}
                                           colorScheme='brand.green'>
                                        Locked
                                    </Badge>
                                    <Badge variant={tokenData.isOwnershipRenounced ? 'solid' : 'outline'}
                                           colorScheme='brand.green'>
                                        Renounced
                                    </Badge>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                <HStack gap={1}>
                                    <IconButton
                                        ml={"auto"}
                                        variant={'outline'}
                                        size='sm'
                                        aria-label='swap'
                                        colorScheme='brand.cyan'
                                        icon={<MdCurrencyExchange style={{fontSize: '24px'}}
                                        />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(setSelectedTokenAddress(tokenData.contractAddress));
                                        }}
                                    />
                                    <IconButton
                                        variant={'outline'}
                                        size='sm'
                                        aria-label='dex'
                                        colorScheme='brand.cyan'
                                        icon={<PiChartLineUpBold style={{fontSize: '24px'}}
                                        />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(tokenData.dexscreenerUrl, '_blank');
                                        }}
                                    />
                                    <Menu placement="right-start">
                                        <MenuButton
                                            size="sm"
                                            as={IconButton}
                                            aria-label='Options'
                                            icon={<ExternalLinkIcon style={{fontSize: '18px'}}/>}
                                            variant='outline'
                                            colorScheme='brand.cyan'
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        <Portal>
                                            <MenuList minWidth='auto' padding='0' zIndex={1} mt={-2.5}
                                                      borderRadius='full'>
                                                <Box bg={'gray.200'} borderRadius='full' p={2}>
                                                    <HStack spacing={1}>
                                                        <IconButton colorScheme={"blackAlpha"}
                                                                    size='sm'
                                                                    aria-label='Search'
                                                                    icon={<FaXTwitter/>}
                                                                    aria-disabled={tokenData.xurl === null}
                                                                    disabled={tokenData.xurl == null}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        tokenData.xurl !== null && window.open(tokenData.xurl, '_blank');
                                                                    }}
                                                        />
                                                        <IconButton colorScheme={'telegram'}
                                                                    size='sm'
                                                                    aria-label='Search'
                                                                    icon={<PiTelegramLogo/>}
                                                                    aria-disabled={tokenData.telegramUrl === null}
                                                                    disabled={tokenData.telegramUrl === null}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        tokenData.telegramUrl !== null && window.open(tokenData.telegramUrl, '_blank');
                                                                    }}
                                                        />
                                                        <IconButton colorScheme="brand.purple"
                                                                    size='sm'
                                                                    aria-label='Search'
                                                                    icon={<BsGlobe/>}
                                                                    aria-disabled={tokenData.websiteUrl === null}
                                                                    disabled={tokenData.websiteUrl == null}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        tokenData.websiteUrl !== null && window.open(tokenData.websiteUrl, '_blank');
                                                                    }}
                                                        />
                                                    </HStack>
                                                </Box>
                                            </MenuList>
                                        </Portal>
                                    </Menu>
                                </HStack>
                            </GridItem>
                        </Grid>
                        <TokenRowCollapse
                            tokenData={tokenData}
                            isRowOpen={rowCollapse.isOpen}
                            onTokenImageModalOpen={imageModal.onOpen}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </>
    );
};

export default TokenRow;