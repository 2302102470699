import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./store";
import {TokenData, TopTraderInfo} from "../interfaces/datatypes";

export interface TopTraderInfoAndToken {
    topTraderInfo: TopTraderInfo[];
    tokenAddress: string;
    tokenSymbol: string;
}

interface TimelineState {
    swapFeedTabIndex: number;
    tokenFeedSortColumn: string;
    selectedTokenAddress?: string;
    isInitialTokenFeedFetch: boolean;
    tokenFeedData: TokenData[];
    traderInfoModalOpen: boolean;
    traderInfoModalData: TopTraderInfoAndToken;
}

const initialState: TimelineState = {
    swapFeedTabIndex: 0,
    tokenFeedSortColumn: 'score',
    selectedTokenAddress: undefined,
    isInitialTokenFeedFetch: true,
    tokenFeedData: [],
    traderInfoModalOpen: false,
    traderInfoModalData: {
        topTraderInfo: [],
        tokenAddress: '',
        tokenSymbol: '',
    },
};

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setSwapFeedTabIndex: (state, action: PayloadAction<number>) => {
            state.swapFeedTabIndex = action.payload;
        },
        setTokenFeedSortColumn: (state, action: PayloadAction<string>) => {
            state.tokenFeedSortColumn = action.payload;
        },
        setSelectedTokenAddress: (state, action: PayloadAction<string>) => {
            state.selectedTokenAddress = action.payload;
        },
        setIsInitialTokenFeedFetch: (state, action: PayloadAction<boolean>) => {
            state.isInitialTokenFeedFetch = action.payload;
        },
        resetTimeline: (state) => {
            state.selectedTokenAddress = initialState.selectedTokenAddress;
            state.isInitialTokenFeedFetch = initialState.isInitialTokenFeedFetch;
        },
        setTokenFeedData: (state, action: PayloadAction<TokenData[]>) => {
            state.tokenFeedData = action.payload;
        },
        openTraderInfoModal: (state, action: PayloadAction<TopTraderInfoAndToken>) => {
            state.traderInfoModalOpen = true;
            state.traderInfoModalData = action.payload;
        },
        closeTraderInfoModal: (state) => {
            state.traderInfoModalOpen = false;
            state.traderInfoModalData = initialState.traderInfoModalData;
        },
    },
});


export const getSwapFeedTabIndex = (state: RootState) => state.timeline.swapFeedTabIndex;
export const getTokenFeedSortColumn = (state: RootState) => state.timeline.tokenFeedSortColumn;
export const getSelectedTokenAddress = (state: RootState) => state.timeline.selectedTokenAddress;
export const getIsInitialTokenFeedFetch = (state: RootState) => state.timeline.isInitialTokenFeedFetch;
export const getTokenFeedData = (state: RootState) => state.timeline.tokenFeedData;
export const isTraderInfoModalOpen = (state: RootState) => state.timeline.traderInfoModalOpen;
export const getTraderInfoModalData = (state: RootState) => state.timeline.traderInfoModalData;

export const {
    setSwapFeedTabIndex,
    setTokenFeedSortColumn,
    setSelectedTokenAddress,
    setIsInitialTokenFeedFetch,
    resetTimeline,
    setTokenFeedData,
    openTraderInfoModal,
    closeTraderInfoModal,
} = timelineSlice.actions;
