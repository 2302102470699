import {
    Badge,
    Box,
    Center,
    HStack,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Spinner,
    Switch,
    Text,
    VStack
} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";
import {CheckIcon} from "@chakra-ui/icons";
import {TimelineConfigRequest} from "../../../../interfaces/datatypes";
import {useConfigureTokenFeedMutation} from "../../../../services/timelineService";
import {useDispatch} from "react-redux";
import {setIsInitialTokenFeedFetch} from "../../../../reducers/timelineSlice";

type ConfigModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const ConfigModal: React.FC<ConfigModalProps> = ({isOpen, onClose}) => {
    const dispatch = useDispatch();

    const [configureTokenFeed, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useConfigureTokenFeedMutation();

    const [isVerified, setIsVerified] = useState(false);
    const [isTradingOpen, setIsTradingOpen] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [isRenounced, setIsRenounced] = useState(false);
    const [sliderValue, setSliderValue] = useState(40);
    const [minTopTraders, setMinTopTraders] = useState(0);
    const [minMcap, setMinMcap] = useState('');
    const [maxMcap, setMaxMcap] = useState('');
    const [minMcapError, setMinMcapError] = useState(false);
    const [maxMcapError, setMaxMcapError] = useState(false);


    const getLetterFromScore = (score: number) => {
        if (score === 10) return 'L';
        if (score === 20) return 'F';
        if (score === 30) return 'E';
        if (score === 40) return 'D';
        if (score === 50) return 'C';
        if (score === 60) return 'B';
        if (score === 70) return 'A';
        if (score >= 80) return 'S';
        return 'D';
    }

    const parseMCAPValue = (value: string): string | undefined => {
        if (value === '') {
            return undefined;
        }
        const numValue = parseFloat(value.slice(0, -1));
        if (value.endsWith('B')) {
            return `${numValue}000000000`; // Append 9 zeros
        } else if (value.endsWith('M')) {
            return `${numValue}000000`; // Append 6 zeros
        } else if (value.endsWith('K')) {
            return `${numValue}000`; // Append 3 zeros
        }
        return value;
    };

    const validateMCAPInput = (value: string) => {
        const regex = /^[0-9]+([KMB])$/i;
        return regex.test(value);
    };

    const handleMinMCAPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toUpperCase();
        if (validateMCAPInput(value) || value === '') {
            setMinMcap(value);
            setMinMcapError(false);
        } else {
            setMinMcap(value);
            setMinMcapError(true);
        }
    };

    const handleMaxMCAPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toUpperCase();
        if (validateMCAPInput(value) || value === '') {
            setMaxMcap(value);
            setMaxMcapError(false);
        } else {
            setMaxMcap(value);
            setMaxMcapError(true);
        }
    };

    const updateConfig = () => {
        const requestPayload: TimelineConfigRequest = {
            minLetterGrade: getLetterFromScore(sliderValue),
            requireCodeVerified: isVerified,
            requireTradingOpen: isTradingOpen,
            requireLiquidityLocked: isLocked,
            requireOwnershipRenounced: isRenounced,
            minTotalTopTraders: minTopTraders,
            minMarketCap: parseMCAPValue(minMcap),
            maxMarketCap: parseMCAPValue(maxMcap)
        };
        configureTokenFeed(requestPayload);
    };

    useEffect(() => {
        if (isSuccess) { // Make sure isSuccess is defined or imported correctly
            dispatch(setIsInitialTokenFeedFetch(true));
            onClose();
        }
    }, [isSuccess, onClose, dispatch]);

    useEffect(() => {
        if (isError) {
            console.error('Configure Timeline error:', error);
            onClose();
        }
    }, [isError, error, onClose]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)'/>
            <ModalContent bgColor={'blackAlpha.500'}>
                <ModalBody p={6}>
                    <Center>
                        <HStack>
                            <Slider minH='32' orientation='vertical' defaultValue={sliderValue} min={10} max={80}
                                    step={10}
                                    ml={4} mr={3}
                                    onChange={(val) => setSliderValue(val)}
                            >
                                <SliderMark value={10} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    L
                                </SliderMark>
                                <SliderMark value={20} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    F
                                </SliderMark>
                                <SliderMark value={30} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    E
                                </SliderMark>
                                <SliderMark value={40} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    D
                                </SliderMark>
                                <SliderMark value={50} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    C
                                </SliderMark>
                                <SliderMark value={60} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    B
                                </SliderMark>
                                <SliderMark value={70} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    A
                                </SliderMark>
                                <SliderMark value={80} mb='-2.5' ml='-5' fontSize='sm' color={'whiteSmoke'}>
                                    S
                                </SliderMark>
                                <SliderTrack bg='green.300'>
                                    <Box position='relative' right={10}/>
                                    <SliderFilledTrack bg='red.300'/>
                                </SliderTrack>
                                <SliderThumb boxSize={2} color={'whiteSmoke'}/>
                            </Slider>
                            <VStack spacing={1}>
                                <HStack w="100%" justifyContent="space-between">
                                    <Badge variant={isVerified ? 'solid' : 'outline'} colorScheme="green">
                                        Verified
                                    </Badge>
                                    <Switch
                                        colorScheme="green"
                                        size="md"
                                        isChecked={isVerified}
                                        onChange={() => setIsVerified(!isVerified)}
                                    />
                                </HStack>
                                <HStack w="100%" justifyContent="space-between">
                                    <Badge variant={isTradingOpen ? 'solid' : 'outline'} colorScheme="green">
                                        Open
                                    </Badge>
                                    <Switch
                                        colorScheme="green"
                                        size="md"
                                        isChecked={isTradingOpen}
                                        onChange={() => setIsTradingOpen(!isTradingOpen)}
                                    />
                                </HStack>
                                <HStack w="100%" justifyContent="space-between">
                                    <Badge variant={isLocked ? 'solid' : 'outline'} colorScheme="green">
                                        Locked
                                    </Badge>
                                    <Switch
                                        colorScheme="green"
                                        size="md"
                                        isChecked={isLocked}
                                        onChange={() => setIsLocked(!isLocked)}
                                    />
                                </HStack>
                                <HStack w="100%" justifyContent="space-between">
                                    <Badge variant={isRenounced ? 'solid' : 'outline'} colorScheme="green">
                                        Renounced
                                    </Badge>
                                    <Switch
                                        colorScheme="green"
                                        size="md"
                                        isChecked={isRenounced}
                                        onChange={() => setIsRenounced(!isRenounced)}
                                    />
                                </HStack>
                            </VStack>
                            <VStack>
                                <HStack w="100%" justifyContent="space-between">
                                    <Text fontSize='xs'>
                                        Min Top Traders:
                                    </Text>
                                    <NumberInput size='xs' maxW={16} defaultValue={0} min={0} max={50}
                                                 onChange={(value) => setMinTopTraders(parseInt(value))}
                                                 value={minTopTraders}>
                                        <NumberInputField/>
                                        <NumberInputStepper>
                                            <NumberIncrementStepper color={"whitesmoke"}/>
                                            <NumberDecrementStepper color={"whitesmoke"}/>
                                        </NumberInputStepper>
                                    </NumberInput>
                                </HStack>
                                <HStack w="100%" justifyContent="space-between">
                                    <Text fontSize='xs'>
                                        Min MCAP:
                                    </Text>
                                    <Input
                                        size='xs'
                                        maxW={16}
                                        value={minMcap}
                                        onChange={handleMinMCAPChange}
                                        isInvalid={minMcapError}
                                        placeholder="69K"
                                    />
                                </HStack>
                                <HStack w="100%" justifyContent="space-between">
                                    <Text fontSize='xs'>
                                        Max MCAP:
                                    </Text>
                                    <Input
                                        size='xs'
                                        maxW={16}
                                        value={maxMcap}
                                        onChange={handleMaxMCAPChange}
                                        isInvalid={maxMcapError}
                                        placeholder="420M"
                                    />
                                </HStack>
                            </VStack>
                            <IconButton
                                pt={0}
                                pb={0}
                                mt={0}
                                mb={0}
                                variant={'outline'}
                                size='lg'
                                h="125px"
                                aria-label='Confirm'
                                colorScheme='whiteAlpha'
                                icon={isLoading ? <Spinner/> : <CheckIcon style={{fontSize: '20px'}}/>}
                                onClick={updateConfig}
                                isLoading={isLoading}
                                isDisabled={isLoading || minMcapError || maxMcapError}
                            />
                        </HStack>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ConfigModal;
