import React, {useEffect, useRef, useState} from 'react';
import {Button, CircularProgress} from '@chakra-ui/react';

interface LongPressButtonProps {
    isLoading: boolean,
    isInvalid: boolean,
    onLongPress: () => void,
    buttonText: string,
    buttonWidth: string,
    buttonHeight: string,
    buttonTransform: string,
}

const LongPressButton: React.FC<LongPressButtonProps> = (props) => {
    const [progress, setProgress] = useState(0);
    const [isPressing, setIsPressing] = useState(false); // State for pressing
    const hasInvokedRef = useRef(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isPressing && !hasInvokedRef.current) {
            timer = setInterval(() => {
                setProgress(prev => {
                    if (prev >= 100 && !hasInvokedRef.current) {
                        clearInterval(timer);
                        hasInvokedRef.current = true;
                        props.onLongPress();
                        setIsPressing(false); // Reset pressing
                        return 0;
                    }
                    return prev + 20;
                });
            }, 1000 / 5);
        } else {
            setProgress(0);
            hasInvokedRef.current = false;
        }

        return () => {
            if (timer !== null) {
                clearInterval(timer);
            }
        };
    }, [props, isPressing]);


    const handleMouseDown = () => {
        setIsPressing(true);
    };

    const handleMouseUp = () => {
        setIsPressing(false);
    };

    return (
        <Button
            ml={props.buttonText === 'Send' ? "auto" : 0}
            transform={props.buttonTransform}
            bg={"blackAlpha.600"}
            color={"whitesmoke"}
            colorScheme={"whiteAlpha"}
            size='lg'
            h={props.buttonHeight}
            w={props.buttonWidth}
            isLoading={props.isLoading}
            isDisabled={props.isLoading || props.isInvalid}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            {isPressing ? (
                <CircularProgress
                    value={progress}
                    color="green.200"
                    size="24px"
                    isIndeterminate={false}
                />
            ) : (
                props.buttonText
            )}
        </Button>
    );
};

export default LongPressButton;
