import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {PositionsResponse} from "../interfaces/datatypes";
import {RootState} from "../reducers/store";

export const positionService = createApi({
    reducerPath: 'positionService',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.marflow.live/v1/tx/',
        prepareHeaders: (headers, {getState}) => {
            const state = getState() as RootState;
            const sessionData = state.session.sessionData;
            if (sessionData?.apiKey && sessionData?.username) {
                headers.set('X-API-KEY', sessionData.apiKey);
                headers.set('username', sessionData.username);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        openPositions: builder.query<PositionsResponse, void>({
            query: () => ({
                url: 'positions/open',
                method: 'GET',
            }),
        }),
        closedPositions: builder.query<PositionsResponse, void>({
            query: () => ({
                url: 'positions/closed',
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useOpenPositionsQuery,
    useClosedPositionsQuery,
} = positionService;