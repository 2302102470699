import React from "react";
import {
    CircularProgress,
    CircularProgressLabel,
    Collapse,
    Flex,
    Grid,
    GridItem,
    HStack,
    Stat,
    StatGroup,
    StatHelpText,
    StatLabel,
    StatNumber
} from "@chakra-ui/react";
import {TokenData} from "../../../../interfaces/datatypes";

type TokenRowCollapseProps = {
    tokenData: TokenData;
    isRowOpen: boolean;
    onTokenImageModalOpen: () => void;
};

const TokenRowCollapse: React.FC<TokenRowCollapseProps> = ({tokenData, isRowOpen, onTokenImageModalOpen}) => {

    const getPercentColor = (percent: number) => {

        if (percent > 90) {
            return 'green.400';
        }
        if (percent > 80) {
            return 'yellow.400';
        }
        if (percent > 70) {
            return 'orange.400';
        }
        return 'red.400';

    }

    return (
        <Collapse in={isRowOpen} animateOpacity>
            <Flex
                direction="column"
                p='5px'
                pb='0px'
                pr='0px'
                color='whitesmoke'
                mt='1'
                bg='gray.500'
                rounded='md'
                shadow='md'
            >
                <Grid
                    // templateAreas={`"image contentUpper"
                    //                 "image contentLower"`}
                    // gridTemplateRows={'1fr 7fr'}
                    // gridTemplateColumns={'1fr 7fr'}
                    height={"200px"}
                >

                    {/*{isRowOpen && <GridItem gridArea={'image'}>*/}
                    {/*    <Box bg={"gray.300"} p={1} m={1} borderRadius={'base'}>*/}
                    {/*        <Image*/}
                    {/*            objectFit={'cover'}*/}
                    {/*            src="/scoreAnimatedSmall.gif"*/}
                    {/*            alt="ScoreImage"*/}
                    {/*            h={"100px"}*/}
                    {/*            w={"100px"}*/}
                    {/*            borderRadius='md'*/}
                    {/*            onClick={onTokenImageModalOpen}*/}
                    {/*        />*/}
                    {/*    </Box>*/}
                    {/*</GridItem>}*/}

                    {isRowOpen && <GridItem ml="2" mr="0">
                        <StatGroup ml='auto' w='100%'>
                            <Stat>
                                <StatLabel fontSize={"16px"}>Holders</StatLabel>
                                <StatNumber fontSize={"24px"}>{tokenData.holderCount}</StatNumber>
                                <StatHelpText mb={1} fontSize={"16px"} color={"green.200"}>
                                    {tokenData.largeBuyCount} Large buy(s)
                                </StatHelpText>
                            </Stat>
                            <Stat>
                                <StatLabel fontSize={"16px"}>Snipes</StatLabel>
                                <StatNumber fontSize={"24px"}>{tokenData.snipeCount}</StatNumber>
                                <StatHelpText mb={1} fontSize={"16px"} color={"green.200"}>
                                    {tokenData.preApproveCount} Pre Approves
                                </StatHelpText>
                            </Stat>
                            <Stat>
                                <StatLabel fontSize={"16px"}>Top Traders</StatLabel>
                                <StatNumber fontSize={"24px"}>
                                    {(tokenData.topTraderCount ? tokenData.topTraderCount : 0)} / {tokenData.totalTopTraderCount ? tokenData.totalTopTraderCount : 0}
                                </StatNumber>
                                <StatHelpText mb={1} fontSize={"16px"} color={"green.200"}>
                                    {tokenData.topNamedCount} (.eth) {tokenData.topAnonCount} (anon)
                                </StatHelpText>
                                {(tokenData.cheaterCount ? tokenData.cheaterCount : 0) > 0 && (
                                    <StatHelpText mb={1} fontSize={"16px"} color={"red.300"}>
                                        {tokenData.cheaterCount} (cheaters)
                                    </StatHelpText>
                                )}
                            </Stat>
                            <Stat>
                                <StatLabel fontSize={"16px"}>Buy Volume</StatLabel>
                                <StatNumber fontSize={"24px"}>{tokenData.buyVolumeETH?.toFixed(2)} ETH</StatNumber>
                                <StatHelpText mb={1} fontSize={"16px"} color={"brand.red.200"}>
                                    Buy
                                    tax {tokenData.buyTaxPercent?.toFixed ? tokenData.buyTaxPercent?.toFixed(2) : "?"}%
                                </StatHelpText>
                            </Stat>
                            <Stat>
                                <StatLabel fontSize={"16px"}>Sell Volume</StatLabel>
                                <StatNumber fontSize={"24px"}>{tokenData.sellVolumeETH?.toFixed(2)} ETH</StatNumber>
                                <StatHelpText mb={1} fontSize={"16px"} color={"brand.red.200"}>
                                    Sell
                                    tax {tokenData.sellTaxPercent?.toFixed ? tokenData.sellTaxPercent.toFixed(2) : "?"}%
                                </StatHelpText>
                            </Stat>
                        </StatGroup>
                    </GridItem>}

                    {isRowOpen && <GridItem>
                        <HStack ml={2} mr={"auto"}>
                            <Stat>
                                <HStack>
                                    <CircularProgress size='80px'
                                                      value={Math.round(tokenData.totalSupplyInLiquidityPercent)}
                                                      color={getPercentColor(tokenData.totalSupplyInLiquidityPercent)}>
                                        <CircularProgressLabel>{Math.round(tokenData.totalSupplyInLiquidityPercent)}%</CircularProgressLabel>
                                    </CircularProgress>
                                    <StatLabel whiteSpace={"nowrap"} fontSize={"16px"}
                                               mt={-1}>Supply
                                        In Liquidity</StatLabel>
                                </HStack>
                            </Stat>
                            <Stat>
                                <HStack>
                                    <CircularProgress size='80px' value={Math.round(tokenData.liquidityLockedPercent)}
                                                      color={getPercentColor(tokenData.liquidityLockedPercent)}>
                                        <CircularProgressLabel>{Math.round(tokenData.liquidityLockedPercent)}%</CircularProgressLabel>
                                    </CircularProgress>
                                    <StatLabel whiteSpace={"nowrap"} fontSize={"16px"}
                                               mt={-1}>Liquidity
                                        Locked for {tokenData.liquidityLockedDurationDays} days</StatLabel>
                                </HStack>
                            </Stat>
                            <Stat>
                                <HStack>
                                    <CircularProgress size='80px' value={Math.round(tokenData.riskPercent)}
                                                      color={getPercentColor(100 - tokenData.riskPercent)}>
                                        <CircularProgressLabel>{Math.round(tokenData.riskPercent)}%</CircularProgressLabel>
                                    </CircularProgress>
                                    <StatLabel whiteSpace={"nowrap"} fontSize={"16px"}
                                               mt={-1}>Risk Factor</StatLabel>
                                </HStack>
                            </Stat>
                        </HStack>
                    </GridItem>}
                </Grid>
            </Flex>
        </Collapse>
    );
};

export default TokenRowCollapse;