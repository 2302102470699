import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {timelineSlice} from './timelineSlice';
import {sessionSlice} from './sessionSlice';
import {loginService} from '../services/loginService';
import {timelineService} from '../services/timelineService';
import {leaderboardService} from "../services/leaderboardService";
import {positionService} from "../services/positionService";
import {swapService} from "../services/swapService";
import {dashboardSlice} from "./dashboardSlice";

export const store = configureStore({
    reducer: combineReducers({
        timeline: timelineSlice.reducer,
        session: sessionSlice.reducer,
        dashboard: dashboardSlice.reducer,
        [loginService.reducerPath]: loginService.reducer,
        [timelineService.reducerPath]: timelineService.reducer,
        [leaderboardService.reducerPath]: leaderboardService.reducer,
        [positionService.reducerPath]: positionService.reducer,
        [swapService.reducerPath]: swapService.reducer,
    }),

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            loginService.middleware,
            timelineService.middleware,
            leaderboardService.middleware,
            positionService.middleware,
            swapService.middleware
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;