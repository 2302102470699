import {extendTheme, type ThemeConfig} from '@chakra-ui/react'

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: true,
}

// Custom colors
const customColors = {
    brand: {

        greyscale: {
            50: "#FFFFFF",
            100: "#EEEEEE",
            200: "#DDDDDD",
            300: "#CCCCCC",
            400: "#AAAAAA",
            500: "#888888",
            600: "#666666",
            700: "#444444",
            800: "#222222",
            900: "#000000"
        },

        red: {
            50: "#FDE8E9",
            100: "#F9BDC2",
            200: "#F5939A",
            300: "#F26973",
            400: "#EE3F4C",
            500: "#EA1524",
            600: "#BB111D",
            700: "#8C0D16",
            800: "#5E080F",
            900: "#2F0407"
        },
        green: {
            "50": "#EDF7ED",
            "100": "#CDEACD",
            "200": "#ADDCAD",
            "300": "#8DCE8D",
            "400": "#6CC06C",
            "500": "#4CB34C",
            "600": "#3D8F3D",
            "700": "#2E6B2E",
            "800": "#1F471F",
            "900": "#0F240F"
        },
        orange: {
            50: "#FEF6E6",
            100: "#FDE5B9",
            200: "#FCD58C",
            300: "#FBC45F",
            400: "#FBB432",
            500: "#FAA305",
            600: "#C88204",
            700: "#966203",
            800: "#644102",
            900: "#322101"
        },
        blue: {
            50: "#EBF0FA",
            100: "#C7D4F0",
            200: "#A3B8E6",
            300: "#7F9CDC",
            400: "#5A81D2",
            500: "#3665C9",
            600: "#2B51A1",
            700: "#213D78",
            800: "#162850",
            900: "#0B1428"
        },
        purple: {
            50: "#FAEBF5",
            100: "#F1C6E2",
            200: "#E8A1D0",
            300: "#DF7CBD",
            400: "#D657AB",
            500: "#CD3298",
            600: "#A4287A",
            700: "#7B1E5B",
            800: "#52143D",
            900: "#290A1E"
        },
        cyan: {
            50: "#F0EEF7",
            100: "#D5CFE7",
            200: "#BAB1D8",
            300: "#9F92C9",
            400: "#8374B9",
            500: "#6855AA",
            600: "#534488",
            700: "#3F3366",
            800: "#2A2244",
            900: "#151122"
        },
        yellow: {
            50: "#FFF5E6",
            100: "#FEE3B9",
            200: "#FDD28B",
            300: "#FDC05E",
            400: "#FCAE31",
            500: "#FC9C03",
            600: "#C97D03",
            700: "#975E02",
            800: "#653F01",
            900: "#321F01"
        },
        pink: {
            50: "#FCE8EF",
            100: "#F8BFD2",
            200: "#F396B6",
            300: "#EE6C99",
            400: "#EA437D",
            500: "#E51A60",
            600: "#B7154D",
            700: "#8A0F3A",
            800: "#5C0A26",
            900: "#2E0513"
        }
    }
};

const theme = extendTheme({
    config,
    colors: customColors,
});

export default theme