import React, {useEffect, useState} from 'react';
import {
    Box,
    HStack,
    IconButton,
    Link,
    Progress,
    SlideFade,
    Tab,
    Table,
    TableContainer,
    TabList,
    Tabs,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {LeaderboardRequest, Player} from "../../../interfaces/datatypes";
import {Icon} from "@chakra-ui/icons";
import {IoStar, IoStarOutline} from "react-icons/io5";
import {useFavoritesMutation, useLeaderboardQuery} from "../../../services/leaderboardService";

function Leaderboard() {

    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [players, setPlayers] = useState<Player[]>([]);
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortField, setSortField] = useState<string | null | keyof Player>(null);

    const requestPayload: LeaderboardRequest = {
        namedPlayersOnly: activeTabIndex === 0,
        favoritesOnly: activeTabIndex === 2,
        startIndex: 0,
        endIndex: 100,
    };

    const {
        data: leaderboardData,
        refetch,
        isFetching: isFetchingLeaderboardData,
        isSuccess: isLeaderboardSuccess,
        isError: isLeaderboardError,
        error: leaderboardError
    } = useLeaderboardQuery(requestPayload, {
        refetchOnMountOrArgChange: 60
    });

    const [updateFavorites] = useFavoritesMutation();

    const sortedPlayers = React.useMemo(() => {
        let sortedArray: { rank: number; player: Player }[] = players?.map((player, index) => ({
            rank: index + 1,
            player
        }));

        if (sortField === 'rank') {
            sortedArray = sortDirection === 'asc' ? sortedArray : [...sortedArray].reverse();
        } else {
            const key = sortField as keyof Player;
            sortedArray.sort((a, b) => {
                const aValue = a.player[key];
                const bValue = b.player[key];
                if (aValue === undefined || bValue === undefined) return 0;
                if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
                return 0;
            });
        }

        return sortedArray;
    }, [players, sortField, sortDirection]);

    const handleFavoriteClick = (playerAddress: string) => {
        const isFavorite = players.find(player => player.walletAddress === playerAddress)?.favorite ?? false;
        updateFavorites({add: !isFavorite, walletAddress: playerAddress})
            .unwrap()
            .then(() => {
                // Directly update the state upon successful mutation
                setPlayers(prevPlayers => prevPlayers.map(player => {
                    if (player.walletAddress === playerAddress) {
                        return {...player, favorite: !player.favorite};
                    }
                    return player;
                }));
            })
            .catch(error => {
                // Handle error if needed
                console.error('Failed to update favorite status', error);
            });
    };

    useEffect(() => {
        if (isLeaderboardSuccess && leaderboardData) {
            setPlayers(leaderboardData.players)
        }
    }, [isLeaderboardSuccess, leaderboardData]);

    useEffect(() => {
        if (isLeaderboardError) {
            console.error('An error occurred while fetching leaderboard data:', leaderboardError);
        }
    }, [isLeaderboardError, leaderboardError]);

    useEffect(() => {
        refetch();
    }, [activeTabIndex, refetch]);

    return (
        <SlideFade in={true} offsetY='50px'>
            <Box bg='gray.700'
                 rounded='md'
                 shadow='md'
                 w={"100%"}
                 height='80vh'  // 100% viewport height
                 overflowY={'auto'}
                 sx={{
                     // scrollbarWidth: 'thin',
                     scrollbarColor: '#3a3b3c',
                     '&::-webkit-scrollbar': {
                         width: '6px',
                     },
                     '&::-webkit-scrollbar-track': {
                         background: '#3a3b3c',
                     },
                     '&::-webkit-scrollbar-thumb': {
                         background: 'gray.500',
                         borderRadius: '10px',
                     }
                 }}
            >
                <TableContainer>
                    <HStack>
                        <Tabs
                            display='flex'
                            alignItems='center'
                            m={1}
                            fontSize={"13px"}
                            id='show-anon'
                            colorScheme={"brand.orange"}
                            index={activeTabIndex}
                            onChange={(index) => setActiveTabIndex(index)}
                            variant='soft-rounded'>
                            <TabList>
                                <Tab>ENS</Tab>
                                <Tab>All</Tab>
                                <Tab>Favorites</Tab>
                            </TabList>
                        </Tabs>
                        {isFetchingLeaderboardData &&
                            <Progress mr={6}
                                      mt={1.5}
                                      w={"200%"}
                                      borderRadius={'full'}
                                      size='xs'
                                      isIndeterminate
                                      colorScheme={'brand.orange'}
                            />
                        }
                    </HStack>
                    <Table size='sm' variant='striped' colorScheme={'blackAlpha'}>
                        <Thead>
                            <Tr>
                                <Th onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('favorite');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }}>
                                    <Icon as={IoStar}
                                          ml={"-1px"}
                                          style={{
                                              color: sortField === 'favorite' ? 'whiteAlpha.900' : 'whiteAlpha.400',
                                              fontSize: '16px'
                                          }}/>
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('rank');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'rank' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    Rank
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('rr');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'rr' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    RR
                                </Th>
                                <Th fontSize={"10px"} onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('ensName');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'ensName' ? 'whiteAlpha.900' : 'whiteAlpha.400'}
                                    borderRight="1px solid"
                                    borderBottom="0px solid"
                                    borderColor="whiteAlpha.400">
                                    Name
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('pnl');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'pnl' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    PnL
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('wlr');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'wlr' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    W/L
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('tokensTradedCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'tokensTradedCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}
                                    borderRight="1px solid"
                                    borderBottom="0px solid"
                                    borderColor="whiteAlpha.400">
                                    Tokens Traded
                                </Th>

                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('smallBuyCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'smallBuyCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    S
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('mediumBuyCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'mediumBuyCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    M
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('largeBuyCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'largeBuyCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}
                                    borderRight="1px solid"
                                    borderBottom="0px solid"
                                    borderColor="whiteAlpha.400">
                                    L
                                </Th>

                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('snipeCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'snipeCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    Snipe
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('earlyBuyCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'earlyBuyCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    Early
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('lateBuyCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'lateBuyCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}
                                    borderRight="1px solid"
                                    borderBottom="0px solid"
                                    borderColor="whiteAlpha.400">
                                    Late
                                </Th>

                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('quickFlipCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'quickFlipCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    quickflip
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('longHoldCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'longHoldCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    longhold
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('dcaCount');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'dcaCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}
                                    borderRight="1px solid"
                                    borderBottom="0px solid"
                                    borderColor="whiteAlpha.400">
                                    DCA
                                </Th>

                                {/*<Th fontSize={"10px"} isNumeric onClick={() => {*/}
                                {/*    if (sortDirection === 'desc') {*/}
                                {/*        setSortField(null);*/}
                                {/*    } else {*/}
                                {/*        setSortField('totalBuyCount');*/}
                                {/*    }*/}
                                {/*    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');*/}
                                {/*}} color={sortField === 'totalBuyCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>*/}
                                {/*    Buys*/}
                                {/*</Th>*/}
                                {/*<Th fontSize={"10px"} isNumeric onClick={() => {*/}
                                {/*    if (sortDirection === 'desc') {*/}
                                {/*        setSortField(null);*/}
                                {/*    } else {*/}
                                {/*        setSortField('totalSellCount');*/}
                                {/*    }*/}
                                {/*    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');*/}
                                {/*}} color={sortField === 'totalSellCount' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>*/}
                                {/*    Sells*/}
                                {/*</Th>*/}

                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('averageBuysPerToken');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'averageBuysPerToken' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    AVG Buy Count
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('averageSellsPerToken');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'averageSellsPerToken' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    AVG Sell Count
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('averageBuySizePerTrade');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'averageBuySizePerTrade' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    AVG Buy size
                                </Th>
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('averageSellSizePerTrade');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }}
                                    color={sortField === 'averageSellSizePerTrade' ? 'whiteAlpha.900' : 'whiteAlpha.400'}
                                    borderRight="1px solid"
                                    borderBottom="0px solid"
                                    borderColor="whiteAlpha.400">
                                    AVG Sell size
                                </Th>
                                {/*<Th fontSize={"10px"} isNumeric onClick={() => {*/}
                                {/*    if (sortDirection === 'desc') {*/}
                                {/*        setSortField(null);*/}
                                {/*    } else {*/}
                                {/*        setSortField('averageBuyFeePerTrade');*/}
                                {/*    }*/}
                                {/*    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');*/}
                                {/*}} color={sortField === 'averageBuyFeePerTrade' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>*/}
                                {/*    AVG BF*/}
                                {/*</Th>*/}
                                {/*<Th fontSize={"10px"} isNumeric onClick={() => {*/}
                                {/*    if (sortDirection === 'desc') {*/}
                                {/*        setSortField(null);*/}
                                {/*    } else {*/}
                                {/*        setSortField('averageSellFeePerTrade');*/}
                                {/*    }*/}
                                {/*    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');*/}
                                {/*}} color={sortField === 'averageSellFeePerTrade' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>*/}
                                {/*    AVG SF*/}
                                {/*</Th>*/}
                                <Th fontSize={"10px"} isNumeric onClick={() => {
                                    if (sortDirection === 'desc') {
                                        setSortField(null);
                                    } else {
                                        setSortField('averageBlocksInvolved');
                                    }
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                }} color={sortField === 'averageBlocksInvolved' ? 'whiteAlpha.900' : 'whiteAlpha.400'}>
                                    AVG Blocks
                                </Th>

                            </Tr>

                        </Thead>
                        <Tbody>
                            {sortedPlayers.map(({rank, player}) => (
                                <Tr key={rank}>
                                    <Td>
                                        <IconButton
                                            isRound={false}
                                            size={"xxs"}
                                            variant='ghost'
                                            color={player.favorite ? "gold" : "whitesmoke"}
                                            aria-label='favorite'
                                            icon={player.favorite ? <IoStar/> : <IoStarOutline/>}
                                            onClick={() => handleFavoriteClick(player.walletAddress)}
                                        />
                                    </Td>
                                    <Td fontSize={"12px"} isNumeric>{rank}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.rr}</Td>
                                    <Td borderRight="1px solid"
                                        borderBottom="0px solid"
                                        borderColor="whiteAlpha.400"
                                        fontSize={"12px"}>
                                        {
                                            <Link href={`https://etherscan.io/address/${player.walletAddress}`}
                                                  isExternal>
                                                {
                                                    player.ensName
                                                        ? player.ensName.length > 40
                                                            ? `${player.ensName.substring(0, 40)}...`
                                                            : player.ensName
                                                        : player.walletAddress
                                                }
                                            </Link>
                                        }
                                    </Td>

                                    <Td fontSize={"12px"} isNumeric>{player.pnl.toFixed(2)}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.wlr.toFixed(2)}</Td>
                                    <Td borderRight="1px solid"
                                        borderBottom="0px solid"
                                        borderColor="whiteAlpha.400"
                                        fontSize={"12px"} isNumeric>{player.tokensTradedCount}</Td>

                                    <Td fontSize={"12px"} isNumeric>{player.smallBuyCount}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.mediumBuyCount}</Td>
                                    <Td borderRight="1px solid"
                                        borderBottom="0px solid"
                                        borderColor="whiteAlpha.400"
                                        fontSize={"12px"} isNumeric>{player.largeBuyCount}</Td>

                                    <Td fontSize={"12px"} isNumeric>{player.snipeCount}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.earlyBuyCount}</Td>
                                    <Td borderRight="1px solid"
                                        borderBottom="0px solid"
                                        borderColor="whiteAlpha.400"
                                        fontSize={"12px"} isNumeric>{player.lateBuyCount}</Td>

                                    <Td fontSize={"12px"} isNumeric>{player.quickFlipCount}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.longHoldCount}</Td>
                                    <Td borderRight="1px solid"
                                        borderBottom="0px solid"
                                        borderColor="whiteAlpha.400"
                                        fontSize={"12px"} isNumeric>{player.dcaCount}</Td>

                                    {/*<Td fontSize={"12px"} isNumeric>{player.totalBuyCount}</Td>*/}
                                    {/*<Td fontSize={"12px"} isNumeric>{player.totalSellCount}</Td>*/}

                                    <Td fontSize={"12px"} isNumeric>{player.averageBuysPerToken.toFixed(1)}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.averageSellsPerToken.toFixed(1)}</Td>
                                    <Td fontSize={"12px"} isNumeric>{player.averageBuySizePerTrade.toFixed(2)}</Td>
                                    <Td borderRight="1px solid"
                                        borderBottom="0px solid"
                                        borderColor="whiteAlpha.400"
                                        fontSize={"12px"} isNumeric>{player.averageSellSizePerTrade.toFixed(2)}</Td>
                                    {/*<Td fontSize={"12px"} isNumeric>{player.averageBuyFeePerTrade.toFixed(4)}</Td>*/}
                                    {/*<Td fontSize={"12px"} isNumeric>{player.averageSellFeePerTrade.toFixed(4)}</Td>*/}
                                    <Td fontSize={"12px"} isNumeric>{player.averageBlocksInvolved.toFixed(1)}</Td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </SlideFade>
    );
}

export default Leaderboard;