import React from "react";
import {HStack, IconButton, Tooltip} from "@chakra-ui/react";
import {GiPodiumWinner} from "react-icons/gi";
import {CgFeed} from "react-icons/cg";
import {MdMonitorHeart} from "react-icons/md";

interface PageNavProps {
    setViewCallback: (view: string) => void;
}

const PageNav: React.FC<PageNavProps> = ({setViewCallback}) => {
    return (
        <HStack mt={2} gap={1}>
            <Tooltip fontSize={"xs"} mt={-1} bg={'brand.cyan.200'} w={"100px"} textAlign={'center'} borderRadius={"md"} label='Leaderboard'>
                <IconButton
                    size='sm'
                    colorScheme="brand.cyan"
                    variant={'outline'}
                    w={"100px"}
                    onClick={() => setViewCallback('leaderboard')}
                    aria-label='Leaderboard'
                    icon={<GiPodiumWinner style={{fontSize: '24px'}}/>}
                />
            </Tooltip>
            <Tooltip fontSize={"xs"} mt={-1} bg={'brand.cyan.200'} w={"100px"} textAlign={'center'} borderRadius={"md"} label='Timeline'>
                <IconButton
                    size='sm'
                    colorScheme="brand.cyan"
                    variant={'outline'}
                    w={"100px"}
                    onClick={() => setViewCallback('timeline')}
                    aria-label='Timeline'
                    icon={<CgFeed style={{fontSize: '24px'}}/>}
                />
            </Tooltip>
            <Tooltip fontSize={"xs"} mt={-1} bg={'brand.cyan.200'} w={"100px"} textAlign={'center'} borderRadius={"md"} label='Trade'>
                <IconButton
                    size='sm'
                    colorScheme="brand.cyan"
                    variant={'outline'}
                    w={"100px"}
                    onClick={() => setViewCallback('dashboard')}
                    aria-label='Trade'
                    icon={<MdMonitorHeart style={{fontSize: '24px'}}/>}
                />
            </Tooltip>

        </HStack>
    );
};

export default PageNav;