import React, {useMemo} from 'react';
import {
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    IconButton,
    Progress,
    ScaleFade,
    Tab,
    TabList,
    Tabs,
    useDisclosure
} from "@chakra-ui/react";
import TokenRow from "./content/TokenRow";
import SwapFeedItem from "./content/SwapFeedItem";
import AnomalyFeedItem from "./content/AnomalyFeedItem";
import {useAnomalyFeedQuery, useSwapFeedQuery, useTokenFeedQuery} from "../../../services/timelineService";
import {useDispatch, useSelector} from "react-redux";
import {
    getSwapFeedTabIndex,
    getTokenFeedSortColumn,
    setSwapFeedTabIndex,
    setTokenFeedSortColumn
} from "../../../reducers/timelineSlice";
import {TimelineRequest} from "../../../interfaces/datatypes";
import {sortData} from "./util/timelineUtils";
import TraderInfoModal from "./content/TraderInfoModal";
import {TbFilterCog} from "react-icons/tb";
import ConfigModal from "./content/ConfigModal";

function Timeline() {
    const dispatch = useDispatch();
    const configModal = useDisclosure();

    // const tokenFeedData = useSelector(getTokenFeedData);
    const swapFeedTabIndex = useSelector(getSwapFeedTabIndex);
    // const initialFetch = useSelector(getIsInitialTokenFeedFetch);
    const tokenFeedSortColumn = useSelector(getTokenFeedSortColumn);

    const timelineRequest: TimelineRequest = {
        // newOnly: !initialFetch,
        newOnly: false,
        // lookbackSeconds: initialFetch ? 86400 : undefined,
        lookbackSeconds: 86400,
        ensSwapsOnly: swapFeedTabIndex === 0,
        favoriteSwapsOnly: swapFeedTabIndex === 2,
        topNTraderSwaps: 100,
        mostRecentNSwaps: 100
    };

    const {
        data: newTokenFeedData,
        isFetching: isFetchingTokenFeed,
        // isSuccess: isTokenFeedFetchSuccess,
    } = useTokenFeedQuery(
        timelineRequest, {
            // pollingInterval: initialFetch ? 0 : 10000,
            pollingInterval: 10000,
            skipPollingIfUnfocused: false,
            refetchOnMountOrArgChange: true
        });

    const {
        data: swapFeedData,
    } = useSwapFeedQuery(
        timelineRequest, {
            pollingInterval: 15000,
            skipPollingIfUnfocused: false,
            refetchOnMountOrArgChange: false
        });

    const {
        data: anomalyFeedData,
    } = useAnomalyFeedQuery(
        timelineRequest, {
            pollingInterval: 20000,
            skipPollingIfUnfocused: false,
            refetchOnMountOrArgChange: false
        });

    // useEffect(() => {
    //     // Merge new data on successful fetch and not on initial fetch
    //     if (isTokenFeedFetchSuccess && newTokenFeedData) {
    //         if (initialFetch) {
    //             // For initial fetch, just set the data
    //             dispatch(setTokenFeedData(newTokenFeedData.timelineUpdates));
    //             dispatch(setIsInitialTokenFeedFetch(false));
    //         } else if (!initialFetch) {
    //             // Assuming mergeData function merges and removes duplicates appropriately
    //             const mergedData = mergeData(newTokenFeedData.timelineUpdates, tokenFeedData);
    //             dispatch(setTokenFeedData(mergedData));
    //         }
    //     }
    //
    // }, [dispatch, newTokenFeedData, isTokenFeedFetchSuccess]);

    // Use useMemo for sorting to avoid unnecessary re-calculations
    const sortedSlicedTokenFeedData = useMemo(() => {
        return sortData(tokenFeedSortColumn, newTokenFeedData?.timelineUpdates ?? []).slice(0, 1000);
    }, [tokenFeedSortColumn, newTokenFeedData?.timelineUpdates]);

    const tokenRows = useMemo(() => {
        return sortedSlicedTokenFeedData.map((tokenData, index) => (
            <TokenRow key={index} tokenData={tokenData}/>
        ))
    }, [sortedSlicedTokenFeedData]);

    const anomalyFeedItems = useMemo(() => {
        let sortedAnomalies = anomalyFeedData
            ? [
                ...anomalyFeedData?.anomalies.largeLiquidityAdds,
                ...anomalyFeedData?.anomalies.largeSwaps,
                ...anomalyFeedData?.anomalies.frequentTokenSymbols,
                ...anomalyFeedData?.anomalies.preappSnipes,
            ].sort((a, b) => b.blockNumber - a.blockNumber)
            : [];

        // Mapping over the sorted, combined anomalies to render components
        return sortedAnomalies.map((anomaly, index) => (
            <AnomalyFeedItem key={index} anomalyData={anomaly} itemType={anomaly.type}/>
        ));
    }, [anomalyFeedData]);

    const swapFeedItems = useMemo(() => {
        return swapFeedData?.traderSwaps.map((swap, index) => (
            <SwapFeedItem key={index} swapData={swap}/>
        ))
    }, [swapFeedData]);

    const handleSwapTabChange = (index: number) => {
        dispatch(setSwapFeedTabIndex(index))
    };

    return (
        <>
            <ConfigModal
                isOpen={configModal.isOpen}
                onClose={configModal.onClose}
            />
            <TraderInfoModal/>
            <ScaleFade in={true} initialScale={0.75}>
                <Grid gap={5} gridTemplateColumns={'3fr 9fr 3fr'}>
                    <GridItem>
                        <Center>
                            <Tabs
                                display='flex'
                                alignItems='center'
                                m={1}
                                mt={"-44px"}
                                fontSize={"13px"}
                                id='show-swaps'
                                colorScheme={"brand.orange"}
                                index={swapFeedTabIndex}
                                onChange={(index) => handleSwapTabChange(index)}
                                variant='soft-rounded'>
                                <TabList>
                                    <Tab>ENS</Tab>
                                    <Tab>All</Tab>
                                    <Tab>Favorites</Tab>
                                </TabList>
                            </Tabs>
                        </Center>
                        <Box bg='gray.700'
                             rounded='md'
                             shadow='md'
                            // overflowY="hidden"
                             overflowX="hidden"
                            // height="800px"
                             height='80vh'  // 100% viewport height
                             overflowY={'auto'}
                             w={"100%"}
                             sx={{
                                 // scrollbarWidth: 'thin',
                                 scrollbarColor: '#3a3b3c',
                                 '&::-webkit-scrollbar': {
                                     width: '6px',
                                 },
                                 '&::-webkit-scrollbar-track': {
                                     background: '#3a3b3c',
                                 },
                                 '&::-webkit-scrollbar-thumb': {
                                     background: 'gray.500',
                                     borderRadius: '10px',
                                 }
                             }}
                        >
                            {swapFeedItems}
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Grid ml={1}>
                            <Grid
                                mt={"-30px"}
                                columnGap={1}
                                alignItems="center"
                                gridTemplateColumns={'9fr 20fr 10fr 10fr 10fr 10fr 10fr 34fr 10fr'}
                                h={"100%"}
                                w={"100%"}
                                zIndex={10}>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('score'))}
                                            bg={tokenFeedSortColumn === 'score' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        Score
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('ticker'))}
                                            bg={tokenFeedSortColumn === 'ticker' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        Ticker
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('age'))}
                                            bg={tokenFeedSortColumn === 'age' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        Age
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('activity'))}
                                            bg={tokenFeedSortColumn === 'activity' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        Activity
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('traders'))}
                                            bg={tokenFeedSortColumn === 'traders' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        Traders
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('mcap'))}
                                            bg={tokenFeedSortColumn === 'mcap' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        MCAP
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button onClick={() => dispatch(setTokenFeedSortColumn('delta'))}
                                            bg={tokenFeedSortColumn === 'delta' ? '#1c222d' : '#747a85'}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        Delta
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button bg={"whiteAlpha.100"}
                                            color={'whitesmoke'}
                                            size='xs'
                                            w={"100%"}>
                                        <Progress bg={"whiteAlpha.100"} w={"99%"} borderRadius={'full'} size='xs'
                                                  isIndeterminate={isFetchingTokenFeed}
                                                  colorScheme={'brand.orange'}/>
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <IconButton
                                        variant={'outline'}
                                        size='xs'
                                        w={"100%"}
                                        aria-label='Config'
                                        colorScheme={'brand.cyan'}
                                        icon={<TbFilterCog style={{fontSize: '20px'}}/>}
                                        onClick={configModal.onOpen}
                                    />
                                </GridItem>
                            </Grid>
                            <Box bg='gray.700'
                                 rounded='md'
                                 shadow='md'
                                 mt={"0px"}
                                // overflowY="hidden"
                                 overflowX="hidden"
                                // height="800px"
                                 height='80vh'  // 100% viewport height
                                 overflowY={'auto'}
                                 w={"100%"}
                                 sx={{
                                     // scrollbarWidth: 'thin',
                                     scrollbarColor: '#3a3b3c',
                                     '&::-webkit-scrollbar': {
                                         width: '6px',
                                     },
                                     '&::-webkit-scrollbar-track': {
                                         background: '#3a3b3c',
                                     },
                                     '&::-webkit-scrollbar-thumb': {
                                         background: 'gray.500',
                                         borderRadius: '10px',
                                     }
                                 }}
                            >
                                {tokenRows}
                            </Box>

                        </Grid>
                    </GridItem>
                    <GridItem>
                        <Box bg='gray.700'
                             rounded='md'
                             shadow='md'
                            // overflowY="hidden"
                             overflowX="hidden"
                            // height="800px"
                             height='80vh'  // 100% viewport height
                             overflowY={'auto'}
                             w={"100%"}
                             sx={{
                                 // scrollbarWidth: 'thin',
                                 scrollbarColor: '#3a3b3c',
                                 '&::-webkit-scrollbar': {
                                     width: '6px',
                                 },
                                 '&::-webkit-scrollbar-track': {
                                     background: '#3a3b3c',
                                 },
                                 '&::-webkit-scrollbar-thumb': {
                                     background: 'gray.500',
                                     borderRadius: '10px',
                                 }
                             }}
                        >
                            {anomalyFeedItems}
                        </Box>
                    </GridItem>
                </Grid>
            </ScaleFade>
        </>
    );
}

export default Timeline;

