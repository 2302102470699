import {TabPanel} from "@chakra-ui/react";
import React from "react";
import {useOpenPositionsQuery} from "../../../../services/positionService";
import {Position} from "../../../../interfaces/datatypes";
import OpenOrder from "./OpenOrder";
import OpenPosition from "./OpenPosition";

function ActivePositions() {
    const sortField = 'createdAt';

    const {
        data: openPositionData,
    } = useOpenPositionsQuery(undefined, {
        pollingInterval: 3000,
        skipPollingIfUnfocused: false,
        refetchOnMountOrArgChange: true
    });

    const openPositions = openPositionData?.openPositions.map(pos => ({
        ...pos,
        createdAt: new Date(pos.createdAt),
    }));

    const sortValues = (aValue: any, bValue: any) => {
        if (aValue === undefined || bValue === undefined) return 0;
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return bValue - aValue;  // For numbers, subtract for the right order
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return bValue.localeCompare(aValue);  // For strings, use localeCompare
        }
        if (aValue instanceof Date && bValue instanceof Date) {
            return bValue.getTime() - aValue.getTime();  // Convert dates to timestamps and subtract
        }
        return 0;
    };

    const sortedOpenPositions = () => {
        if (openPositions) {
            let sortedArray = [...openPositions];
            const key = sortField as keyof Position;
            sortedArray.sort((a, b) => {
                return sortValues(a[key], b[key]);
            });
            return sortedArray;
        }
        return openPositions;
    }

    const rowItems = sortedOpenPositions()?.map((position, index) => (
        position.creationTxHash
            ? <OpenPosition position={position}/>
            : <OpenOrder position={position}/>
    ));

    return (
        <TabPanel>
            {rowItems}
        </TabPanel>
    );
}

export default ActivePositions;