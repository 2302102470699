import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./store";
import {PositionRowAction} from "../components/main/dashboard/Dashboard";

interface DashboardState {
    positionRowAction: PositionRowAction;
}

const initialState: DashboardState = {
    positionRowAction: {
        contractAddress: '',
        actionType: 'buy',
    }
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setPositionRowAction: (state, action: PayloadAction<PositionRowAction>) => {
            state.positionRowAction = action.payload;
        },
        resetPositionRowAction: (state) => {
            state.positionRowAction = initialState.positionRowAction;
        },
    },
});


export const getPositionRowAction = (state: RootState) => state.dashboard.positionRowAction;

export const {
    setPositionRowAction,
    resetPositionRowAction
} = dashboardSlice.actions;
