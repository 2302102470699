import React, {useEffect} from 'react';
import {Box, Grid, GridItem, ScaleFade} from "@chakra-ui/react";
import SwapPanel from "./content/SwapPanel";
import SimulatePanel from "./content/SimulatePanel";
import PositionPanel from "./content/PositionsPanel";
import {useDispatch} from "react-redux";
import {resetPositionRowAction} from "../../../reducers/dashboardSlice";

export interface PositionRowAction {
    contractAddress: string,
    actionType: string
}

function Dashboard() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetPositionRowAction())
    }, [dispatch]);

    return (
        <>
            <ScaleFade in={true} initialScale={0.75}>
                <Box bg='gray.700'
                     rounded='md'
                     shadow='md'
                     overflowX="hidden"
                     height='80vh'  // 100% viewport height
                     overflowY={'auto'}
                     w={"100%"}
                     sx={{
                         // scrollbarWidth: 'thin',
                         scrollbarColor: '#3a3b3c',
                         '&::-webkit-scrollbar': {
                             width: '6px',
                         },
                         '&::-webkit-scrollbar-track': {
                             background: '#3a3b3c',
                         },
                         '&::-webkit-scrollbar-thumb': {
                             background: 'gray.500',
                             borderRadius: '10px',
                         }
                     }}
                >
                    <Grid
                        gridTemplateColumns="1fr 1fr"
                        gridTemplateRows="1fr 1fr"
                        templateAreas="'left1 right' 'left2 right'"
                        gap={3}
                        m={3}
                        h={"96.5%"}
                    >
                        <GridItem gridArea={'left1'} w={"100%"}>
                            <SwapPanel/>
                        </GridItem>
                        <GridItem gridArea={'left2'}>
                            <SimulatePanel/>
                        </GridItem>
                        <GridItem gridArea={'right'}>
                            <PositionPanel/>
                        </GridItem>
                    </Grid>
                </Box>
            </ScaleFade>
        </>
    );
}

export default Dashboard;
