import React, {useEffect, useState} from "react";
import Leaderboard from './leaderboard/Leaderboard';
import Timeline from './timeline/Timeline';
import Dashboard from './dashboard/Dashboard';
import {Center, Flex, Grid, GridItem, HStack, Link, Tag, TagLabel, VStack} from "@chakra-ui/react";
import Searchbar from "./content/Searchbar";
import PageNav from "./content/PageNav";
import {useDispatch, useSelector} from "react-redux";
import {getSessionData} from "../../reducers/sessionSlice";
import {getSelectedTokenAddress, resetTimeline} from "../../reducers/timelineSlice";
import {useUserInfoQuery} from "../../services/timelineService";

function Main() {
    const dispatch = useDispatch();
    const sessionData = useSelector(getSessionData);
    const selectedTokenAddress = useSelector(getSelectedTokenAddress);

    useUserInfoQuery(undefined, {
        pollingInterval: 30000,
        skipPollingIfUnfocused: false,
    });

    const [activeView, setActiveView] = useState<string>('timeline');

    const handleActiveViewChange = (view: string) => {
        if (view === 'timeline' && activeView !== 'timeline') {
            dispatch(resetTimeline());
        }
        setActiveView(view);
    }

    useEffect(() => {

        if (selectedTokenAddress) {
            setActiveView('dashboard');
        }

    }, [selectedTokenAddress]);

    return (
        <Grid
            templateAreas={`"lrail nav account"
                    "lrail main rrail"
                    "lrail content rrail"
                    "footer footer footer"`}
            gridTemplateRows={'1fr 2fr 30fr 1fr'}
            gridTemplateColumns={'1fr 80fr 1fr'}
            height='100vh'  // 100% viewport height
            width='100%' // 100% viewport width
            gridColumnGap={0}
            gridRowGap={1}
            color='whitesmoke'
            fontWeight='bold'
        >


            <GridItem bg={"gray.900"} gridArea={'lrail'}>
                <Flex h={"100%"} w={"100%"}/>
            </GridItem>

            <GridItem bg={"gray.900"} gridArea={'nav'}>
                <Grid gridTemplateColumns={'6fr 18fr 6fr'}>
                    <GridItem>
                        <Center>
                            <Searchbar/>
                        </Center>
                    </GridItem>
                    <GridItem>
                        <Center>
                            <PageNav setViewCallback={handleActiveViewChange}/>
                        </Center>
                    </GridItem>
                    <GridItem>
                        <Flex justifyContent={"end"} h={"100%"} w={"100%"}>
                            <Tag mt={2} size='sm' bgColor={'brand.orange.400'}>
                                <VStack gap={0}>
                                    <TagLabel mb={0} pb={0} pt={"2px"} fontSize={"16px"} color={'brand.purple.600'}>
                                        <HStack>
                                            <Link href={`https://etherscan.io/address/${sessionData?.walletAddress}`}
                                                  isExternal>
                                                {sessionData?.username}
                                            </Link>
                                            <TagLabel pt={1} mb={0} pb={0} fontSize={"14px"}
                                                      color={'brand.purple.400'}>{sessionData?.walletBalance} ETH</TagLabel>
                                        </HStack>
                                    </TagLabel>
                                    <TagLabel mb={0} pb={"4px"} fontSize={"10px"}
                                              color={'brand.purple.800'}>Rank {sessionData?.rank} ({sessionData?.rr} RR)</TagLabel>
                                </VStack>
                            </Tag>
                        </Flex>
                    </GridItem>
                </Grid>
            </GridItem>


            <GridItem bg={"gray.900"} gridArea={'account'}>
            </GridItem>

            <GridItem gridArea={'main'}>
            </GridItem>

            <GridItem gridArea={'content'}
                      width={"100%"}
                      mx="auto">
                {activeView === 'leaderboard' && <Leaderboard/>}
                {activeView === 'timeline' && <Timeline/>}
                {activeView === 'dashboard' && <Dashboard/>}
            </GridItem>

            <GridItem bg='gray.900' gridArea={'footer'}/>

        </Grid>
    );
}

export default Main;
