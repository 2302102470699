import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {LoginRequest, LoginResponse} from "../interfaces/datatypes";

export const loginService = createApi({
    reducerPath: 'loginService',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.marflow.live/v1/'
    }),
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (credentials) => ({
                url: 'login',
                method: 'POST',
                body: credentials,
            }),
        }),
    }),
});

export const {useLoginMutation} = loginService;