import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {
    EstimateRequest,
    EstimateResponse,
    ExecutionRequest,
    ExecutionResponse,
    OrderRequest,
    SimulateRequest,
    SimulateResponse,
    TransactionStatusResponse
} from "../interfaces/datatypes";
import {RootState} from "../reducers/store";

export const swapService = createApi({
    reducerPath: 'swapService',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.marflow.live/v1/tx/',
        prepareHeaders: (headers, {getState}) => {
            const state = getState() as RootState;
            const sessionData = state.session.sessionData;
            if (sessionData?.apiKey && sessionData?.username) {
                headers.set('X-API-KEY', sessionData.apiKey);
                headers.set('username', sessionData.username);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        estimate: builder.query<EstimateResponse, EstimateRequest>({
            query: (request) => ({
                url: 'estimate',
                method: 'POST',
                body: request,
            }),
        }),
        status: builder.query<TransactionStatusResponse, string | null>({
            query: (txRequestId) => ({
                url: `status?requestId=${txRequestId}`,
                method: 'GET',
            }),
        }),
        execute: builder.mutation<ExecutionResponse, ExecutionRequest>({
            query: (request) => ({
                url: 'execute',
                method: 'POST',
                body: request,
            }),
        }),
        order: builder.mutation<ExecutionResponse, OrderRequest>({
            query: (request) => ({
                url: 'order',
                method: 'POST',
                body: request,
            }),
        }),
        simulate: builder.mutation<SimulateResponse, SimulateRequest>({
            query: (request) => ({
                url: 'simulate',
                method: 'POST',
                body: request,
            }),
        }),
        simulateScrape: builder.mutation<SimulateResponse, SimulateRequest>({
            query: (request) => ({
                url: 'simulate-scrape',
                method: 'POST',
                body: request,
            }),
        }),
    }),
});

export const {
    useEstimateQuery,
    useStatusQuery,
    useExecuteMutation,
    useOrderMutation,
    useSimulateMutation,
    useSimulateScrapeMutation
} = swapService;