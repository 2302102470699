import React, {useState} from "react";
import {Card, CardBody, Tab, TabList, TabPanels, Tabs} from "@chakra-ui/react";
import ActivePositions from "./ActivePositions";
import ClosedPositions from "./ClosedPositions";

function PositionPanel() {

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    return (
        <Card bg={'gray.600'}
              shadow={'inner'}
              h={"100%"}
              overflowY={'auto'}
              sx={{
                  // scrollbarWidth: 'thin',
                  scrollbarColor: '#3a3b3c',
                  '&::-webkit-scrollbar': {
                      width: '6px',
                  },
                  '&::-webkit-scrollbar-track': {
                      background: '#3a3b3c',
                  },
                  '&::-webkit-scrollbar-thumb': {
                      background: 'gray.500',
                      borderRadius: '10px',
                  }
              }}
        >
            <CardBody p={1}>
                <Tabs isFitted
                      variant='line'
                      colorScheme='whiteAlpha'
                      index={activeTabIndex}
                      onChange={(index) => setActiveTabIndex(index)}
                >
                    <TabList mb='1em'>
                        <Tab>Positions</Tab>
                        <Tab>Trades</Tab>
                    </TabList>
                    <TabPanels>
                        <ActivePositions/>
                        <ClosedPositions/>
                    </TabPanels>
                </Tabs>
            </CardBody>
        </Card>
    );
}

export default PositionPanel;