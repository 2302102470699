import {
    Center,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeTraderInfoModal, getTraderInfoModalData, isTraderInfoModalOpen} from "../../../../reducers/timelineSlice";
import {Position, TopTraderInfo} from "../../../../interfaces/datatypes";

function TraderInfoModal() {
    const dispatch = useDispatch();

    const isModalOpen = useSelector(isTraderInfoModalOpen);
    const {topTraderInfo, tokenAddress, tokenSymbol} = useSelector(getTraderInfoModalData);

    const [sortField, setSortField] = useState<string | null | keyof Position>('tokensBoughtDecimal');

    const sortValues = (aValue: any, bValue: any) => {
        if (aValue === undefined || bValue === undefined) return 0;
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return bValue - aValue;  // For numbers, subtract for the right order
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return bValue.localeCompare(aValue);  // For strings, use localeCompare
        }
        if (aValue instanceof Date && bValue instanceof Date) {
            return bValue.getTime() - aValue.getTime();  // Convert dates to timestamps and subtract
        }
        return 0;
    };

    const sortedTraderInfos = React.useMemo(() => {
        if (topTraderInfo) {
            let sortedArray = [...topTraderInfo];
            const key = sortField as keyof TopTraderInfo;
            sortedArray.sort((a, b) => {
                return sortValues(a[key], b[key]);
            });
            return sortedArray;
        }
        return topTraderInfo;
    }, [topTraderInfo, sortField]);

    return (
        <Modal scrollBehavior={'inside'} isCentered isOpen={isModalOpen}
               onClose={() => dispatch(closeTraderInfoModal())}>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)'/>
            <ModalContent maxWidth="57vw" bgColor={'blackAlpha.500'}>
                <ModalHeader m={0} p={0}>
                    <Center>
                        <Link href={`https://dexscreener.com/ethereum/${tokenAddress}`} isExternal>
                            {tokenSymbol}
                        </Link>
                    </Center>
                </ModalHeader>
                <ModalBody m={0} p={0}>
                    <Center>
                        <TableContainer m={0} p={0} w="57vw" maxHeight="66vh" overflowX="hidden" overflowY="auto" sx={{
                            scrollbarColor: '#3a3b3c',
                            '&::-webkit-scrollbar': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: '#3a3b3c',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: 'gray.500',
                                borderRadius: '10px',
                            }
                        }}>
                            <Table size='sm' variant='striped' colorScheme={'blackAlpha'} >
                                <Thead position="sticky" top="0" zIndex="sticky" bg={'blackAlpha.900'}>
                                    <Tr>
                                        <Th fontSize={"12px"}
                                            color={'whiteAlpha.600'}>
                                            Address
                                        </Th>
                                        <Th fontSize={"12px"}
                                            color={sortField === 'tokensBoughtDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                                            onClick={() => {
                                                setSortField('tokensBoughtDecimal')
                                            }}
                                        >
                                            Tokens Bought
                                        </Th>
                                        <Th fontSize={"12px"}
                                            color={sortField === 'ethSpentDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                                            onClick={() => {
                                                setSortField('ethSpentDecimal')
                                            }}
                                        >
                                            ETH spent
                                        </Th>
                                        <Th fontSize={"12px"}
                                            color={sortField === 'tokensSoldDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                                            onClick={() => {
                                                setSortField('tokensSoldDecimal')
                                            }} isNumeric
                                        >
                                            Tokens Sold
                                        </Th>
                                        <Th fontSize={"12px"}
                                            color={sortField === 'ethReceivedDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                                            onClick={() => {
                                                setSortField('ethReceivedDecimal')
                                            }} isNumeric
                                        >
                                            ETH Received
                                        </Th>
                                        <Th fontSize={"12px"}
                                            color={sortField === 'realizedPnlEthDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                                            onClick={() => {
                                                setSortField('realizedPnlEthDecimal')
                                            }} isNumeric
                                        >
                                            Realized PnL ETH
                                        </Th>
                                        <Th fontSize={"12px"}
                                            color={sortField === 'unrealizedPnlEthDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                                            onClick={() => {
                                                setSortField('unrealizedPnlEthDecimal')
                                            }} isNumeric
                                        >
                                            Unrealized PnL ETH
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {sortedTraderInfos?.map((info, index) => (
                                        <Tr key={index}>
                                            <Td fontSize={"16px"}>
                                                {
                                                    <Link
                                                        href={`https://etherscan.io/address/${info.walletAddress}`}
                                                        isExternal>
                                                        {
                                                            info.ensName
                                                                ? info.ensName.length > 25
                                                                    ? `${info.ensName.substring(0, 25)}...`
                                                                    : info.ensName
                                                                : `${info.walletAddress.substring(0, 6)}...${info.walletAddress.substring(info.walletAddress.length - 4, info.walletAddress.length)}`
                                                        }
                                                    </Link>
                                                }
                                            </Td>
                                            <Td fontSize={"16px"}
                                                color={'whiteAlpha.800'}
                                                isNumeric
                                            >
                                                {info.tokensBoughtDecimalText}
                                            </Td>
                                            <Td fontSize={"16px"}
                                                color={'whiteAlpha.800'}
                                                isNumeric
                                            >
                                                {info.ethSpentDecimal.toFixed(4)} ETH
                                            </Td>
                                            <Td fontSize={"16px"}
                                                color={'whiteAlpha.800'}
                                            >
                                                {info.tokensSoldDecimalText}
                                            </Td>
                                            <Td fontSize={"16px"}
                                                color={'whiteAlpha.800'}
                                                isNumeric
                                            >
                                                {info.ethReceivedDecimal.toFixed(4)} ETH
                                            </Td>
                                            <Td fontSize={"16px"}
                                                color={info.realizedPnlEthDecimal >= 0 ? "green.400" : "red.500"}
                                                isNumeric
                                            >
                                                {info.realizedPnlEthDecimal.toFixed(4)} ETH
                                            </Td>
                                            <Td fontSize={"16px"}
                                                color={'whiteAlpha.800'}
                                                isNumeric
                                            >
                                                {info.unrealizedPnlEthDecimal.toFixed(4)} ETH
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default TraderInfoModal;
