import {Center, Link, Spinner, Table, TableContainer, TabPanel, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import React, {useMemo, useState} from "react";
import {useClosedPositionsQuery} from "../../../../services/positionService";
import {Position} from "../../../../interfaces/datatypes";

function ClosedPositions() {

    const [sortField, setSortField] = useState<string | null | keyof Position>('closedAt');

    const {
        data: closedPositionData,
        isFetching: isClosedPositionFetching,
    } = useClosedPositionsQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    console.log("rendered ClosedPositions" + isClosedPositionFetching)

    const closedPositions = useMemo(() => {
        return closedPositionData?.closedPositions.map(pos => ({
            ...pos,
            createdAt: new Date(pos.createdAt),
            closedAt: new Date(pos.closedAt!),
        }));
    }, [closedPositionData]);

    const sortValues = (aValue: any, bValue: any) => {
        if (aValue === undefined || bValue === undefined) return 0;
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return bValue - aValue;  // For numbers, subtract for the right order
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return bValue.localeCompare(aValue);  // For strings, use localeCompare
        }
        if (aValue instanceof Date && bValue instanceof Date) {
            return bValue.getTime() - aValue.getTime();  // Convert dates to timestamps and subtract
        }
        return 0;
    };

    const sortedClosedPositions = React.useMemo(() => {
        if (closedPositions) {
            let sortedArray = [...closedPositions];
            const key = sortField as keyof Position;
            sortedArray.sort((a, b) => {
                return sortValues(a[key], b[key]);
            });
            return sortedArray;
        }
        return closedPositions;
    }, [closedPositions, sortField]);

    function formatDateToCustom(date: Date) {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(newDate.getDate()).padStart(2, '0');
        const hours = String(newDate.getHours()).padStart(2, '0');
        const minutes = String(newDate.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const positionTable = (
        <TableContainer>
            <Table size='sm' variant='striped' colorScheme={'blackAlpha'}>
                <Thead>
                    <Tr>
                        <Th fontSize={"8px"}
                            color={sortField === 'createdAt' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                            onClick={() => {
                                setSortField('createdAt')
                            }}>Created @</Th>
                        <Th fontSize={"8px"}
                            color={sortField === 'closedAt' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                            onClick={() => {
                                setSortField('closedAt')
                            }}>Closed @</Th>
                        <Th fontSize={"8px"}
                            color={sortField === 'tokenSymbol' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                            onClick={() => {
                                setSortField('tokenSymbol')
                            }}>Token</Th>
                        <Th fontSize={"8px"}
                            color={sortField === 'purchasedAmountEthDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                            onClick={() => {
                                setSortField('purchasedAmountEthDecimal')
                            }} isNumeric>Size</Th>
                        <Th fontSize={"8px"}
                            color={sortField === 'totalCostsEthDecimal' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                            onClick={() => {
                                setSortField('totalCostsEthDecimal')
                            }} isNumeric>Total Cost</Th>
                        <Th fontSize={"8px"}
                            color={sortField === 'realizedProfit' ? 'whiteAlpha.900' : 'whiteAlpha.600'}
                            onClick={() => {
                                setSortField('realizedProfit')
                            }} isNumeric>Realized PnL</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedClosedPositions?.map((position, index) => (
                        <Tr key={index}>
                            <Td fontSize={"12px"}
                                color={'whiteAlpha.800'}>{formatDateToCustom(position.createdAt)}</Td>
                            <Td fontSize={"12px"}
                                color={'whiteAlpha.800'}>{position.closedAt ? formatDateToCustom(position.closedAt) : ''}</Td>
                            <Td fontSize={"12px"} color={'whiteAlpha.800'}><Link
                                href={`https://dexscreener.com/ethereum/${position.contractAddress}`}
                                isExternal>{position.tokenSymbol}</Link></Td>
                            <Td fontSize={"12px"} color={'whiteAlpha.800'}
                                isNumeric>{position.purchasedAmountEthDecimal.toFixed(4)} ETH</Td>
                            <Td fontSize={"12px"} color={'whiteAlpha.800'}
                                isNumeric>{position.totalCostsEthDecimal.toFixed(4)} ETH</Td>
                            <Td fontSize={"12px"} isNumeric
                                color={position.realizedProfit >= 0 ? "green.400" : "red.500"}>{position.realizedProfit.toFixed(4)} ETH</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );

    return (
        <TabPanel>
            {isClosedPositionFetching
                ? <Center>
                    <Spinner
                        thickness='4px'
                        speed='0.9s'
                        emptyColor='gray.400'
                        color='brand.cyan.500'
                        size='xl'
                    />
                </Center>
                : positionTable
            }
        </TabPanel>
    );
}

export default ClosedPositions;