import {Button, Card, CardBody, Grid, GridItem, Link, Tag, Text, VStack} from "@chakra-ui/react";
import React, {FC, useState} from "react";
import {Position} from "../../../../interfaces/datatypes";
import {setPositionRowAction} from "../../../../reducers/dashboardSlice";
import {useDispatch} from "react-redux";

interface PositionProps {
    position: Position
}

const OpenPosition: FC<PositionProps> = ({position}) => {

    const dispatch = useDispatch();

    const [styles, setStyles] = useState({
        bg: 'gray.500',
        shadow: 'dark-lg',
    });

    const handleMouseOver = () => {
        setStyles({
            bg: 'gray.500',
            shadow: 'sm',
        });
    };

    const handleMouseOut = () => {
        setStyles({
            bg: 'gray.500',
            shadow: 'dark-lg',
        });
    };

    return (
        <Card m={1}
              {...styles}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}>
            <CardBody p={2}>
                <Grid gap={3} alignItems="center"
                      gridTemplateColumns={'2fr 3fr 3fr 3fr 3fr 4fr'} h={"100%"}
                      w={"100%"}>
                    <GridItem h={"100%"} w={"100%"}>
                        <Tag h={"100%"}
                             justifyContent="center"
                             w={"100%"} size="md" variant='subtle' colorScheme='cyan'>
                            <Link href={`https://etherscan.io/tx/${position.creationTxHash}`}
                                  isExternal>
                                {position.createdAtText}
                            </Link>
                        </Tag>
                    </GridItem>
                    <GridItem h={"100%"} w={"100%"}>
                        <Tag h={"100%"}
                             justifyContent="center"
                             w={"100%"} size="md" variant='subtle' colorScheme='cyan'>
                            <Link href={`https://dexscreener.com/ethereum/${position.contractAddress}`}
                                  isExternal>
                                {position.tokenSymbol}
                            </Link>
                        </Tag>

                    </GridItem>
                    <GridItem>
                        <Tag h={"100%"}
                             justifyContent="center"
                             w={"100%"} size="md" variant='subtle' bgColor='whiteAlpha.500'>
                            <VStack color={"whitesmoke"}>
                                <Text fontSize={"14px"}>Size</Text>
                                <Text fontSize={"14px"}>
                                    {position.purchasedAmountEthDecimal.toFixed(4)} ETH
                                </Text>
                            </VStack>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Tag h={"100%"}
                             justifyContent="center"
                             w={"100%"} size="md" variant='subtle' bgColor='whiteAlpha.500'>
                            <VStack color={"whitesmoke"}>
                                <Text fontSize={"14px"}>Cost</Text>
                                <Text fontSize={"14px"}>{position.totalCostsEthDecimal.toFixed(4)} ETH
                                </Text>
                            </VStack>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Tag h={"100%"}
                             justifyContent="center"
                             w={"100%"} size="md" variant='subtle' bgColor='whiteAlpha.500'>
                            <VStack color={"whitesmoke"}>
                                <Text fontSize={"14px"}>PnL</Text>
                                <Text fontSize={"14px"}
                                      color={position.unrealizedProfit >= 0 ? "green.500" : "red.600"}>
                                    {position.unrealizedProfit.toFixed(4)} ETH
                                </Text>
                            </VStack>
                        </Tag>
                    </GridItem>
                    <GridItem h={"100%"} w={"100%"}>
                        <Button
                            justifyContent="center"
                            h={"100%"}
                            w={"100%"}
                            mt={0}
                            size={"sm"}
                            bg={"blackAlpha.600"}
                            color={"whitesmoke"}
                            colorScheme={"whiteAlpha"}
                            fontSize={"14px"}
                            // leftIcon={<MdCurrencyExchange style={{fontSize: '20px'}}/>}
                            onClick={() => dispatch(setPositionRowAction({ contractAddress: position.contractAddress, actionType: 'sell' }))}
                            variant='solid'>
                            Sell
                        </Button>
                    </GridItem>
                    {position.takeProfitMultiplier && (
                        <>
                            <GridItem h={"100%"} w={"100%"}>
                            </GridItem>
                            <GridItem h={"100%"} w={"100%"}>
                            </GridItem>
                            <GridItem>
                                <Tag h={"100%"}
                                     justifyContent="center"
                                     w={"100%"} size="md" variant='subtle' bgColor='whiteAlpha.500'>
                                    <VStack color={"whitesmoke"}>
                                        <Text fontSize={"12px"}>Take Profit @</Text>
                                        <Text
                                            fontSize={"14px"}>{position.takeProfitMultiplier ? position.takeProfitMultiplier + 'x' : ''}
                                        </Text>
                                    </VStack>
                                </Tag>
                            </GridItem>
                            <GridItem>
                                <Tag h={"100%"}
                                     justifyContent="center"
                                     w={"100%"} size="md" variant='subtle' bgColor='whiteAlpha.500'>
                                    <VStack color={"whitesmoke"}>
                                        <Text fontSize={"12px"}>Token Sell %</Text>
                                        <Text
                                            fontSize={"16px"}>{position.takeProfitTokenSellPercent ? position.takeProfitTokenSellPercent + '%' : ''}
                                        </Text>
                                    </VStack>
                                </Tag>
                            </GridItem>
                            <GridItem>
                                <Tag h={"100%"}
                                     justifyContent="center"
                                     w={"100%"} size="md" variant='subtle' bgColor='whiteAlpha.500'>
                                    <VStack color={"whitesmoke"}>
                                        <Text fontSize={"12px"}>Stop Loss @</Text>
                                        <Text
                                            fontSize={"16px"}>{position.stopLossMultiplier ? position.stopLossMultiplier + 'x' : ''}
                                        </Text>
                                    </VStack>
                                </Tag>
                            </GridItem>
                            <GridItem h={"100%"} w={"100%"}>
                                <Button
                                    mt={0}
                                    justifyContent="center"
                                    h={"100%"}
                                    w={"100%"}
                                    size={"sm"}
                                    bg={"blackAlpha.600"}
                                    color={"whitesmoke"}
                                    colorScheme={"whiteAlpha"}
                                    fontSize={"14px"}
                                    // leftIcon={<AiOutlineEdit style={{fontSize: '20px'}}/>}
                                    onClick={() => dispatch(setPositionRowAction({ contractAddress: position.contractAddress, actionType: 'order' }))}
                                    variant='solid'>
                                    Edit
                                </Button>
                            </GridItem>
                        </>
                    )}
                </Grid>
            </CardBody>
        </Card>
    );
}

export default OpenPosition;