import React from "react";
import {Flex, IconButton, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";

const Searchbar: React.FC = () => {
    return (
            <InputGroup mt={2} size='sm' width={"90%"} >
                <Input
                    textAlign={'center'}
                    fontSize="xs"
                    borderColor="brand.cyan.300"
                    borderRadius="md"
                    focusBorderColor="brand.cyan.700"
                    placeholder='Search'
                />
                <InputRightElement>
                    <IconButton
                        variant={'ghost'}
                        colorScheme="brand.cyan"
                        size='sm'
                        aria-label='Search'
                        icon={<SearchIcon/>}/>
                </InputRightElement>
            </InputGroup>

    );
};

export default Searchbar;