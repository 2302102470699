import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./store";

interface SessionData {
    username?: string;
    apiKey?: string;
    walletAddress?: string;
    walletBalance?: number;
    rr?: number;
    rank?: number;
}

interface SessionState {
    isAuthenticated: boolean;
    sessionData?: SessionData;
}

const initialState: SessionState = {
    isAuthenticated: false,
    sessionData: undefined,
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setAuthentication: (state, action: PayloadAction<SessionData>) => {
            state.isAuthenticated = true;
            state.sessionData = action.payload;
        },
        setSessionData: (state, action: PayloadAction<Partial<SessionData>>) => {
            state.sessionData = {
                ...state.sessionData,
                ...action.payload,
            };
        },
        clearAuthentication: (state) => {
            state.isAuthenticated = false;
            state.sessionData = undefined;
        },
    },
});


export const getIsAuthenticated = (state: RootState) => state.session.isAuthenticated;
export const getSessionData = (state: RootState) => state.session.sessionData;
export const {
    setAuthentication
} = sessionSlice.actions;
