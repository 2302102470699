import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {
    AnomalyFeedResponse,
    SwapFeedResponse,
    TimelineConfigRequest,
    TimelineRequest,
    TokenFeedResponse,
    UserInfoResponse
} from "../interfaces/datatypes";
import {RootState} from "../reducers/store";
import {sessionSlice} from "../reducers/sessionSlice";

export const timelineService = createApi({
    reducerPath: 'timelineService',
    // tagTypes: ['tokenFeed'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.marflow.live/v1/',
        prepareHeaders: (headers, {getState}) => {
            const state = getState() as RootState;
            const sessionData = state.session.sessionData;
            if (sessionData?.apiKey && sessionData?.username) {
                headers.set('X-API-KEY', sessionData.apiKey);
                headers.set('username', sessionData.username);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        userInfo: builder.query<UserInfoResponse, void>({
            query: () => 'user/info',
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(sessionSlice.actions.setSessionData({
                        walletBalance: data.walletBalance,
                        rr: data.rr,
                        rank: data.rank,
                    }));
                } catch (error) {
                }
            },
        }),
        tokenFeed: builder.query<TokenFeedResponse, TimelineRequest>({
            query: (request) => ({
                url: 'feed/token',
                method: 'POST',
                body: request,
            }),
            // providesTags: ['tokenFeed']
        }),
        swapFeed: builder.query<SwapFeedResponse, TimelineRequest>({
            query: (request) => ({
                url: 'feed/swap',
                method: 'POST',
                body: request,
            }),
        }),
        anomalyFeed: builder.query<AnomalyFeedResponse, TimelineRequest>({
            query: (request) => ({
                url: 'feed/anomaly',
                method: 'POST',
                body: request,
            }),
        }),
        configureTokenFeed: builder.mutation<void, TimelineConfigRequest>({
            query: (request) => ({
                url: 'configure/timeline',
                method: 'POST',
                body: request,
            }),
            // invalidatesTags: ['tokenFeed']
        }),
    }),
});

export const {
    useUserInfoQuery,
    useTokenFeedQuery,
    useSwapFeedQuery,
    useAnomalyFeedQuery,
    useConfigureTokenFeedMutation
} = timelineService;