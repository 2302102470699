import {TraderSwap} from "../../../../interfaces/datatypes";
import React, {useState} from "react";
import {
    Card,
    CardBody,
    Grid,
    GridItem,
    Link,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {FaLeftLong, FaRightLong} from "react-icons/fa6";

interface SwapFeedItemProps {
    swapData: TraderSwap
}

const SwapFeedItem: React.FC<SwapFeedItemProps> = ({swapData}) => {

    const [styles, setStyles] = useState({
        bg: 'gray.600',
        shadow: 'dark-lg',
    });

    const handleMouseOver = () => {
        setStyles({
            bg: 'gray.600',
            shadow: 'sm',
        });
    };

    const handleMouseOut = () => {
        setStyles({
            bg: 'gray.600',
            shadow: 'dark-lg',
        });
    };

    return (
        <>
            <Card m={1}
                  {...styles}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}>
                <CardBody p={2}>
                    <Grid gap={1} alignItems="left" gridTemplateColumns={'1fr 2fr 3fr 2fr'} h={"100%"} w={"100%"}>
                        <GridItem>
                            <Link href={`https://etherscan.io/tx/${swapData.txHash}`} isExternal>
                                <Tag bg={"BUY" === swapData.action ? "brand.green.400" : "brand.red.400"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {swapData.action}
                                    </Text>
                                </Tag>
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link href={`https://dexscreener.com/ethereum/${swapData.tokenAddress}`} isExternal>
                                <Tag bg={"brand.blue.300"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {swapData.tokenSymbol.length > 10 ? `${swapData.tokenSymbol.substring(0, 10)}...` : swapData.tokenSymbol}
                                    </Text>
                                </Tag>
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link href={`https://etherscan.io/address/${swapData.walletAddress}`} isExternal>
                                <Tag bg={"brand.pink.300"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {swapData.ensName
                                            ? (swapData.ensName.length > 15 ? `${swapData.ensName.substring(0, 15)}...` : swapData.ensName)
                                            : `${swapData.walletAddress.substring(0, 6)}...`}
                                    </Text>
                                </Tag>
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Tag bg={"brand.orange.600"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {swapData.rr} RR
                                </Text>
                            </Tag>
                        </GridItem>
                    </Grid>
                    <Grid mt={2} mb={1} gap={1} alignItems="left" gridTemplateColumns={'4fr 1fr 5fr 3.5fr'} h={"100%"}
                          w={"100%"}>
                        {"BUY" === swapData.action &&
                            <>
                                <GridItem>
                                    <Tag bg={"brand.green.400"}
                                         color={"whitesmoke"}
                                         size={"sm"}
                                         w={"100%"}
                                         display="flex"
                                         justifyContent="center"
                                         alignItems="center">
                                        <Text as={"b"}>
                                            {swapData.tokenAmountString} Token
                                        </Text>
                                    </Tag>
                                </GridItem>
                                <GridItem>
                                    <Tag bg={"gray.700"}
                                         color={"whitesmoke"}
                                         size={"sm"}
                                         w={"100%"}
                                         display="flex"
                                         justifyContent="center"
                                         alignItems="center">
                                        <Icon as={FaLeftLong} w={4} h={4} color="whitesmoke"/>
                                    </Tag>
                                </GridItem>
                                <GridItem>
                                    <Tag bg={"brand.red.400"}
                                         color={"whitesmoke"}
                                         size={"sm"}
                                         w={"100%"}
                                         display="flex"
                                         justifyContent="center"
                                         alignItems="center">
                                        <Text as={"b"}>
                                            {swapData.wethAmountDecimal} ETH
                                        </Text>
                                    </Tag>
                                </GridItem>
                            </>
                        }
                        {"SELL" === swapData.action &&
                            <>
                                <GridItem>
                                    <Tag bg={"brand.red.400"}
                                         color={"whitesmoke"}
                                         size={"sm"}
                                         w={"100%"}
                                         display="flex"
                                         justifyContent="center"
                                         alignItems="center">
                                        <Text as={"b"}>
                                            {swapData.tokenAmountString} Token
                                        </Text>
                                    </Tag>
                                </GridItem>
                                <GridItem>
                                    <Tag bg={"gray.700"}
                                         color={"whitesmoke"}
                                         size={"sm"}
                                         w={"100%"}
                                         display="flex"
                                         justifyContent="center"
                                         alignItems="center">
                                        <Icon as={FaRightLong} w={4} h={4} color="whitesmoke"/>
                                    </Tag>
                                </GridItem>
                                <GridItem>
                                    <Tag bg={"brand.green.400"}
                                         color={"whitesmoke"}
                                         size={"sm"}
                                         w={"100%"}
                                         display="flex"
                                         justifyContent="center"
                                         alignItems="center">
                                        <Text as={"b"}>
                                            {swapData.wethAmountDecimal} ETH
                                        </Text>
                                    </Tag>
                                </GridItem>
                            </>
                        }
                        <GridItem>
                            <Tag bg={"brand.blue.900"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {swapData.blockAge}
                                </Text>
                            </Tag>
                        </GridItem>
                    </Grid>
                    <TableContainer>
                        <Table size='sm' variant='striped' colorScheme={'blackAlpha'}>
                            <Thead>
                                <Tr>
                                    <Th py={0} fontSize={"8px"} color={"whiteAlpha.600"}>Tx Value</Th>
                                    <Th py={0} fontSize={"8px"} color={"whiteAlpha.600"}>Gas Price</Th>
                                    <Th py={0} fontSize={"8px"} color={"whiteAlpha.600"}>Max Fee</Th>
                                    <Th py={0} fontSize={"8px"} color={"whiteAlpha.600"}>Max Prio</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td py={0} fontSize={"8px"}
                                        color={'whiteAlpha.800'}>{swapData.txValueDecimal} ETH</Td>
                                    <Td py={0} fontSize={"8px"}
                                        color={'whiteAlpha.800'}>{swapData.gasPriceGwei} Gwei</Td>
                                    <Td py={0} fontSize={"8px"}
                                        color={'whiteAlpha.800'}>{swapData.maxFeePerGasGwei ? `${swapData.maxFeePerGasGwei} Gwei` : 'N/A'}</Td>
                                    <Td py={0} fontSize={"8px"}
                                        color={'whiteAlpha.800'}>{swapData.maxPriorityFeePerGasGwei ? `${swapData.maxPriorityFeePerGasGwei} Gwei` : 'N/A'}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>
        </>
    )
        ;
}

export default SwapFeedItem;
