import React from 'react';
import Login from "./components/login/Login";
import Main from "./components/main/Main";
import {Box, ChakraProvider} from "@chakra-ui/react";
import theme from "./theme";
import {useSelector} from "react-redux";
import {getIsAuthenticated} from "./reducers/sessionSlice";

function App() {
    const isAuthenticated = useSelector(getIsAuthenticated);

    return (
        <ChakraProvider theme={theme}>
            <Box bg="gray.900" minH="100vh" color="white">
                {!isAuthenticated && <Login/>}
                {isAuthenticated && <Main/>}
            </Box>
        </ChakraProvider>
    );
}

export default App;
