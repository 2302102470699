import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputRightAddon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderMark,
    RangeSliderThumb,
    RangeSliderTrack,
    Spinner,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    TagLabel,
    VStack
} from "@chakra-ui/react";
import {ExternalLinkIcon, Icon} from "@chakra-ui/icons";
import {FaCheckCircle, FaEthereum, FaGasPump} from "react-icons/fa";
import {LiaFileContractSolid} from "react-icons/lia";
import {IoMdArrowRoundDown} from "react-icons/io";
import {FaCrosshairs, FaPersonFalling} from "react-icons/fa6";
import {GiTimeBomb, GiToken} from "react-icons/gi";
import {EstimateRequest, ExecutionRequest, OrderRequest} from "../../../../interfaces/datatypes";
import {BiSolidError} from "react-icons/bi";
import LongPressButton from "./LongPressButton";
import {RiCoinsFill} from "react-icons/ri";
import {useSelector} from "react-redux";
import {getSessionData} from "../../../../reducers/sessionSlice";
import {getSelectedTokenAddress} from "../../../../reducers/timelineSlice";
import {useEstimateQuery, useExecuteMutation, useOrderMutation, useStatusQuery} from "../../../../services/swapService";
import {getPositionRowAction} from "../../../../reducers/dashboardSlice";

function SwapPanel() {
    const positionRowAction = useSelector(getPositionRowAction);
    const sessionData = useSelector(getSessionData);
    const contractAddress = useSelector(getSelectedTokenAddress);

    const [txStatus, setTxStatus] = useState<string>('READY')
    const [txHash, setTxHash] = useState<string | null>(null)
    const [txRequestId, setTxRequestId] = useState<string | null>(null)
    const [isAddressValid, setIsAddressValid] = useState(true);
    const [swapContractAddress, setSwapContractAddress] = useState<string | undefined>(contractAddress)
    const [activeTab, setActiveTab] = useState('buy');
    const [gasOption, setGasOption] = useState<"WITHIN_SIX_BLOCKS" | "WITHIN_THREE_BLOCKS" | "ASAP">("WITHIN_SIX_BLOCKS")
    const [slippageOption, setSlippageOption] = useState(5)
    const [ethBuyAmountDecimal, setEthBuyAmountDecimal] = useState(0.1)
    const [tokenSellPercent, setTokenSellPercent] = useState('100')
    const [tabIndex, setTabIndex] = useState(0)
    const [sellOrderEnabled, setSellOrderEnabled] = useState(false);
    const [stopLossSliderValue, setStopLossSliderValue] = useState(0.0)
    const [takeProfitSliderValue, setTakeProfitSliderValue] = useState(2.0)
    const [takeProfitSellPercent, setTakeProfitSellPercent] = React.useState('100')
    const [buyOrderAtTokenPricePercent, setBuyOrderAtTokenPricePercent] = useState('50')
    const [buyOrderValidMinutes, setBuyOrderValidMinutes] = useState('5')
    const [executionLoading, setExecutionLoading] = useState(false);

    const estimateRequest: EstimateRequest = {
        isBuy: activeTab === 'buy' || activeTab === 'order',
        contractAddress: swapContractAddress ?? '',
        ethAmountDecimal: ethBuyAmountDecimal,
        tokenPercentage: activeTab === 'buy' ? undefined : parseInt(tokenSellPercent),
        urgency: gasOption,
        slippagePercent: slippageOption,
    };

    const {
        data: txEstimate,
        isLoading: isEstimateLoading
    } = useEstimateQuery(estimateRequest, {
        pollingInterval: 3000,
        skip: !swapContractAddress || !isAddressValid,
    });

    const {
        data: txStatusResponse,
    } = useStatusQuery(txRequestId, {
        pollingInterval: 3000,
        skip: !txRequestId,
    });

    const [executeSwap, {
        data: executeData,
        isSuccess: isExecuteSwapSuccess,
        isError: isExecuteSwapError,
    }] = useExecuteMutation();

    const [order, {
        isSuccess: isOrderSuccess,
        isError: isOrderError,
    }] = useOrderMutation();

    const addPercent = (val: string) => val + `%`
    const removePercent = (val: string) => val.replace(/^%/, '')

    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    const handleEthBuyAmountChange = (newAmount: number) => {
        if (newAmount < 0.0) {
            newAmount = 0.0;
        }
        setEthBuyAmountDecimal(newAmount);
    };

    const handleTokenSellPercentChange = (newAmount: string) => {
        setTokenSellPercent(newAmount);
    };

    const handleGasOptionChange = (newOption: "WITHIN_SIX_BLOCKS" | "WITHIN_THREE_BLOCKS" | "ASAP") => {
        setGasOption(newOption);
    };

    const handleSlippageOptionChange = (newSlippage: number) => {
        setSlippageOption(newSlippage);
    };

    const handleContractChange = (newAddress: string) => {
        setSwapContractAddress(newAddress);
        if (!newAddress) {
            setIsAddressValid(true)
        } else {
            const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
            setIsAddressValid(ethAddressRegex.test(newAddress));
        }
    };

    const getGasOption = (option: string) => {
        if (option === "WITHIN_SIX_BLOCKS") {
            return "≤ 6 blocks";
        } else if (option === "WITHIN_THREE_BLOCKS") {
            return "≤ 3 blocks";
        } else {
            return "ASAP";
        }
    }

    useEffect(() => {
        if (positionRowAction.contractAddress) {
            setActiveTab(positionRowAction.actionType);
            setTabIndex(positionRowAction.actionType === 'buy'
                ? 0
                : positionRowAction.actionType === 'order'
                    ? 1
                    : 2)
            handleContractChange(positionRowAction.contractAddress)
            handleTokenSellPercentChange('100');
            handleGasOptionChange("ASAP");
            handleSlippageOptionChange(20);
            setSellOrderEnabled(false);
        }
    }, [positionRowAction]);

    useEffect(() => {
        if (txStatusResponse) {
            setTxStatus(txStatusResponse.status);
            setTxHash(txStatusResponse.txHash);

            if (txStatusResponse.status === 'INCLUDED' || txStatusResponse.status === 'FAILED') {
                setExecutionLoading(false);
                setTxRequestId(null);
            }
        }
    }, [txStatusResponse]);

    const handleExecuteSwap = () => {
        if (!swapContractAddress || !isAddressValid) {
            return;
        }
        setTxStatus('BUILDING');
        setExecutionLoading(true);
        const isBuy = activeTab === 'buy'
        const executionRequest: ExecutionRequest = {
            isBuy: isBuy,
            contractAddress: swapContractAddress,
            ethAmountDecimal: isBuy ? ethBuyAmountDecimal : undefined,
            tokenPercentage: isBuy ? undefined : parseInt(removePercent(tokenSellPercent)),
            urgency: gasOption,
            useSlippageGradient: false,
            slippagePercent: slippageOption,
            takeProfitMultiplier: sellOrderEnabled ? takeProfitSliderValue : undefined,
            takeProfitTokenSellPercent: sellOrderEnabled ? parseInt(takeProfitSellPercent) : undefined,
            stopLossMultiplier: sellOrderEnabled ? stopLossSliderValue : undefined,
        };
        executeSwap(executionRequest);
    };

    useEffect(() => {
        if (isExecuteSwapSuccess && executeData) {
            setTxRequestId(executeData.requestId);
        }
    }, [isExecuteSwapSuccess, executeData]);

    useEffect(() => {
        if (isExecuteSwapError) {
            setExecutionLoading(false);
            setTxRequestId(null);
            setTxStatus('FAILED');
        }
    }, [isExecuteSwapError]);

    const handleSendOrder = () => {
        if (!swapContractAddress || !isAddressValid) {
            return;
        }
        setTxStatus('BUILDING');
        setExecutionLoading(true);
        const orderRequest: OrderRequest = {
            isBuy: true, // TODO should isBuy be true?
            contractAddress: swapContractAddress,
            ethAmountDecimal: ethBuyAmountDecimal,
            urgency: gasOption,
            useSlippageGradient: false,
            slippagePercent: slippageOption,
            takeProfitMultiplier: sellOrderEnabled ? takeProfitSliderValue : undefined,
            takeProfitTokenSellPercent: sellOrderEnabled ? parseInt(takeProfitSellPercent) : undefined,
            stopLossMultiplier: sellOrderEnabled ? stopLossSliderValue : undefined,
            buyOrderAtTokenPricePercent: parseInt(buyOrderAtTokenPricePercent),
            buyOrderValidMinutes: parseInt(buyOrderValidMinutes)
        };
        order(orderRequest);
    };

    useEffect(() => {
        if (isOrderSuccess) {
            setExecutionLoading(false);
            setTxRequestId(null);
            setTxStatus("CREATED");
        }
    }, [isOrderSuccess]);

    useEffect(() => {
        if (isOrderError) {
            setTxRequestId(null);
            setExecutionLoading(false);
            setTxStatus('FAILED');
        }
    }, [isOrderError]);

    return (
        <Grid gap={1} gridTemplateColumns={'3fr 6fr 2fr'} h={"100%"} w={"100%"}>
            <GridItem h={"100%"} w={"100%"}>
                <Card bg={'gray.600'}
                      shadow={'inner'}
                      h={"100%"} w={"100%"}>
                    <CardBody pl={0} pr={0} pt={1}>

                        <VStack mt={1}>
                            <Heading transform="translateY(-2px)" as='h4' size='md'
                                     color={"whitesmoke"}>Sell Trigger</Heading>
                            <HStack pr={0}>
                                <Switch ml={-2} mr={"auto"} colorScheme={"brand.orange"} size='lg'
                                        isChecked={sellOrderEnabled}
                                        onChange={(e) => setSellOrderEnabled(e.target.checked)}
                                />
                                <Icon as={GiToken}
                                      ml={"auto"}
                                      style={{color: "whitesmoke", fontSize: '20px'}}/>
                                <NumberInput focusBorderColor={"blackAlpha.500"} size='lg'
                                             isDisabled={!sellOrderEnabled}
                                             defaultValue={100} precision={0}
                                             step={10}
                                             min={10}
                                             max={100}
                                             w={"100px"}
                                             onChange={(valueString) => setTakeProfitSellPercent(removePercent(valueString))}
                                             value={addPercent(takeProfitSellPercent)}>
                                    <NumberInputField color={"whitesmoke"}/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper color={"whitesmoke"}/>
                                        <NumberDecrementStepper color={"whitesmoke"}/>
                                    </NumberInputStepper>
                                </NumberInput>
                            </HStack>
                            <HStack>
                                <RangeSlider orientation={'vertical'} mr={"auto"} mt={3} mb={2}
                                             isDisabled={!sellOrderEnabled}
                                             h={"300px"}
                                             pl={0}
                                             defaultValue={[0.0, 2.0]}
                                             min={-0.2} max={4.2} step={0.2}
                                             transform="translateX(-80px)"
                                             onChange={(val) => {
                                                 if (val[0] > 0.8) {
                                                     val[0] = 0.8;
                                                 }
                                                 if (val[0] < 0.0) {
                                                     val[0] = 0.0;
                                                 }
                                                 if (val[1] < 1.2) {
                                                     val[1] = 1.2;
                                                 }
                                                 if (val[1] > 4.0) {
                                                     val[1] = 4.0;
                                                 }
                                                 setStopLossSliderValue(val[0]);
                                                 setTakeProfitSliderValue(val[1])
                                             }}>
                                    <RangeSliderMark
                                        value={takeProfitSliderValue}
                                        fontSize={"14px"}
                                        textAlign='center'
                                        bg='blackAlpha.500'
                                        borderColor={"whiteAlpha.800"}
                                        borderWidth={"2px"}
                                        borderRadius={'full'}
                                        color='white'
                                        w={"150px"}
                                        transform="translateY(13px) translateX(0px)"
                                    >
                                        Take Profit {takeProfitSliderValue}x
                                    </RangeSliderMark>
                                    <RangeSliderMark
                                        value={stopLossSliderValue}
                                        fontSize={"14px"}
                                        textAlign='center'
                                        borderColor={"whiteAlpha.800"}
                                        borderWidth={"2px"}
                                        bg='blackAlpha.500'
                                        borderRadius={'full'}
                                        color='white'
                                        w={"150px"}
                                        transform="translateY(13px) translateX(0px)"
                                    >
                                        Stop Loss {stopLossSliderValue}x
                                    </RangeSliderMark>
                                    <RangeSliderTrack
                                        width={8}
                                        bg="linear-gradient(to top, red, black 25%, green)"
                                        borderRadius={'base'}
                                    >
                                        <RangeSliderFilledTrack bg='transparent'/>
                                    </RangeSliderTrack>
                                    <RangeSliderThumb bg={'whiteAlpha.500'} borderRadius={'none'}
                                                      maxH="25px" boxSize={8} index={0}/>
                                    <RangeSliderThumb bg={'whiteAlpha.500'} borderRadius={'none'}
                                                      maxH="25px" boxSize={8} index={1}/>
                                </RangeSlider>
                            </HStack>
                        </VStack>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem h={"100%"} w={"100%"}>
                <Card bg={'gray.600'}
                      shadow={'inner'}
                      h={"100%"} w={"100%"}>
                    <CardBody pl={2} pr={2} pt={1} pb={0}>
                        <Tabs isFitted
                              variant={'enclosed'}
                              m={0}
                              p={0}
                              index={tabIndex}
                              onChange={handleTabsChange}
                        >
                            <TabList w={"100%"} m={0} mb={2} borderRadius={'base'}>
                                <Tab borderColor={"whiteAlpha.400"} onClick={() => setActiveTab('buy')}><Heading
                                    mt={0} pt={0} as='h4' size='md'
                                    color={activeTab === 'buy' ? "whitesmoke" : "gray.500"}
                                >Buy</Heading></Tab>
                                <Tab borderColor={"whiteAlpha.400"} onClick={() => setActiveTab('order')}><Heading
                                    mt={0} pt={0} as='h4' size='md'
                                    color={activeTab === 'order' ? "whitesmoke" : "gray.500"}>Order</Heading></Tab>
                                <Tab borderColor={"whiteAlpha.400"} onClick={() => setActiveTab('sell')}><Heading
                                    mt={0} pt={0} as='h4' size='md'
                                    color={activeTab === 'sell' ? "whitesmoke" : "gray.500"}>Sell</Heading></Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel m={0} p={0}>
                                    <VStack mt={1}>
                                        <HStack>
                                            <Icon as={LiaFileContractSolid}
                                                  style={{color: "whitesmoke", fontSize: '26px'}}/>
                                            <Input focusBorderColor={"blackAlpha.500"} fontSize={"16px"}
                                                   size="lg"
                                                   variant='outline' w={"400px"} color="whitesmoke"
                                                   placeholder='0x977c5fcf7a552d38adcde4f41025956855497c6d'
                                                   value={swapContractAddress}
                                                   isInvalid={!isAddressValid}
                                                   errorBorderColor='crimson'
                                                   onChange={(e) => handleContractChange(e.target.value)}
                                            />
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaEthereum}
                                                  style={{color: "whitesmoke", fontSize: '26px'}}/>
                                            <InputGroup size='lg' w={"400px"}>
                                                <NumberInput focusBorderColor={"blackAlpha.500"}
                                                             defaultValue={0.1} precision={4}
                                                             step={0.025}
                                                             min={0.0}
                                                             max={sessionData?.walletBalance}
                                                             w={"400px"}
                                                             value={ethBuyAmountDecimal}
                                                             onChange={(val) => handleEthBuyAmountChange(parseFloat(val))}
                                                >
                                                    <NumberInputField textAlign={'center'} color={"whitesmoke"}/>
                                                    <NumberInputStepper w={"50px"}>
                                                        <NumberIncrementStepper color={"whitesmoke"}/>
                                                        <NumberDecrementStepper color={"whitesmoke"}/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <InputRightAddon ml={2} mr={"1px"} borderRadius={'base'}
                                                                 children="ETH"/>
                                            </InputGroup>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaGasPump}
                                                  transform="translateX(5px)"
                                                  style={{color: "whitesmoke", fontSize: '22px'}}/>
                                            <Menu>
                                                <MenuButton size="lg" pl={"0px"} ml={"5px"} pr={"4px"}
                                                            variant={'outline'}
                                                            color={'whitesmoke'} w={"400px"} as={Button}
                                                    // colorScheme={"blackAlpha"}
                                                    // borderColor={"blackAlpha.500"}
                                                            rightIcon={<IoMdArrowRoundDown
                                                                color={'whitesmoke'}/>}
                                                >
                                                    {getGasOption(gasOption)}
                                                </MenuButton>
                                                <MenuList p={0} bg={'gray.800'} w={'400px'}>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("WITHIN_SIX_BLOCKS")}
                                                    >
                                                        ≤ 6 blocks
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("WITHIN_THREE_BLOCKS")}
                                                    >
                                                        ≤ 3 blocks
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("ASAP")}
                                                    >
                                                        ASAP
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaPersonFalling}
                                                  style={{color: "whitesmoke", fontSize: '24px'}}/>
                                            <Menu>
                                                <MenuButton size="lg" pl={"0px"} pr={"4px"}
                                                            variant={'outline'}
                                                            color='whitesmoke' w={"400px"} as={Button}
                                                    // colorScheme={"blackAlpha"}
                                                            rightIcon={<IoMdArrowRoundDown
                                                                color={'whitesmoke'}/>}>
                                                    {slippageOption}%
                                                </MenuButton>
                                                <MenuList p={0} bg={'gray.800'} w={'400px'}>
                                                    {/*<MenuItem*/}
                                                    {/*    textAlign="center"*/}
                                                    {/*    borderBottomColor={"blackAlpha.300"}*/}
                                                    {/*    borderBottomWidth={"1px"}*/}
                                                    {/*    bg={'transparent'}*/}
                                                    {/*    color={'whitesmoke'}*/}
                                                    {/*    onClick={() => setSlippageOption('SlippageGradient™')}*/}
                                                    {/*>*/}
                                                    {/*    SlippageGradient™*/}
                                                    {/*</MenuItem>*/}
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(5)}
                                                    >
                                                        5%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(10)}
                                                    >
                                                        10%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(20)}
                                                    >
                                                        20%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(40)}
                                                    >
                                                        40%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(80)}
                                                    >
                                                        80%
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <HStack>
                                            <Spinner style={{opacity: isEstimateLoading ? 1 : 0}} ml={-2} mb={1.5}
                                                     color={'whitesmoke'} speed={'1.0s'} size='md'/>
                                            <Stat color={'whitesmoke'} w={"120px"}>
                                                <StatLabel>Estimated Total</StatLabel>
                                                <StatNumber>{txEstimate?.grandTotalEstimateEth ? txEstimate?.grandTotalEstimateEth.toFixed(3) : ethBuyAmountDecimal.toFixed(3)} ETH</StatNumber>
                                                <StatHelpText
                                                    fontSize={"10px"}>({txEstimate?.totalSizeEstimateEth} + {txEstimate?.totalFeeEstimateEth?.toFixed(3)} gas)</StatHelpText>
                                            </Stat>
                                            <LongPressButton buttonTransform={"translateX(10px)"} buttonWidth={"260px"}
                                                             buttonHeight={"80px"} buttonText={'Send'}
                                                             isLoading={executionLoading}
                                                             isInvalid={!isAddressValid || !swapContractAddress || isEstimateLoading}
                                                             onLongPress={handleExecuteSwap}/>
                                        </HStack>
                                    </VStack>
                                </TabPanel>
                                <TabPanel m={0} p={0}>
                                    <VStack mt={1}>
                                        <HStack>
                                            <Icon as={LiaFileContractSolid}
                                                  style={{color: "whitesmoke", fontSize: '26px'}}/>
                                            <Input focusBorderColor={"blackAlpha.500"} fontSize={"16px"}
                                                   size="lg"
                                                   variant='outline' w={"400px"} color="whitesmoke"
                                                   placeholder='0x977c5fcf7a552d38adcde4f41025956855497c6d'
                                                   value={swapContractAddress}
                                                   isInvalid={!isAddressValid}
                                                   errorBorderColor='crimson'
                                                   onChange={(e) => handleContractChange(e.target.value)}
                                            />
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaEthereum}
                                                  style={{color: "whitesmoke", fontSize: '26px'}}/>
                                            <InputGroup size='lg' w={"400px"}>
                                                <NumberInput focusBorderColor={"blackAlpha.500"}
                                                             defaultValue={0.1} precision={4}
                                                             step={0.025}
                                                             min={0.0}
                                                             max={sessionData?.walletBalance}
                                                             w={"400px"}
                                                             value={ethBuyAmountDecimal}
                                                             onChange={(val) => handleEthBuyAmountChange(parseFloat(val))}
                                                >
                                                    <NumberInputField textAlign={'center'} color={"whitesmoke"}/>
                                                    <NumberInputStepper w={"50px"}>
                                                        <NumberIncrementStepper color={"whitesmoke"}/>
                                                        <NumberDecrementStepper color={"whitesmoke"}/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <InputRightAddon ml={2} mr={"1px"} borderRadius={'base'}
                                                                 children="ETH"/>
                                            </InputGroup>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaGasPump}
                                                  transform="translateX(5px)"
                                                  style={{color: "whitesmoke", fontSize: '22px'}}/>
                                            <Menu>
                                                <MenuButton size="lg" pl={"0px"} ml={"5px"} pr={"4px"}
                                                            variant={'outline'}
                                                            color={'whitesmoke'} w={"400px"} as={Button}
                                                    // colorScheme={"blackAlpha"}
                                                    // borderColor={"blackAlpha.500"}
                                                            rightIcon={<IoMdArrowRoundDown
                                                                color={'whitesmoke'}/>}
                                                >
                                                    {getGasOption(gasOption)}
                                                </MenuButton>
                                                <MenuList p={0} bg={'gray.800'} w={'400px'}>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("WITHIN_SIX_BLOCKS")}
                                                    >
                                                        ≤ 6 blocks
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("WITHIN_THREE_BLOCKS")}
                                                    >
                                                        ≤ 3 blocks
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("ASAP")}
                                                    >
                                                        ASAP
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaPersonFalling}
                                                  style={{color: "whitesmoke", fontSize: '24px'}}/>
                                            <Menu>
                                                <MenuButton size="lg" pl={"0px"} pr={"4px"}
                                                            variant={'outline'}
                                                            color='whitesmoke' w={"400px"} as={Button}
                                                    // colorScheme={"blackAlpha"}
                                                            rightIcon={<IoMdArrowRoundDown
                                                                color={'whitesmoke'}/>}>
                                                    {slippageOption}%
                                                </MenuButton>
                                                <MenuList p={0} bg={'gray.800'} w={'400px'}>
                                                    {/*<MenuItem*/}
                                                    {/*    textAlign="center"*/}
                                                    {/*    borderBottomColor={"blackAlpha.300"}*/}
                                                    {/*    borderBottomWidth={"1px"}*/}
                                                    {/*    bg={'transparent'}*/}
                                                    {/*    color={'whitesmoke'}*/}
                                                    {/*    onClick={() => setSlippageOption('SlippageGradient™')}*/}
                                                    {/*>*/}
                                                    {/*    SlippageGradient™*/}
                                                    {/*</MenuItem>*/}
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(5)}
                                                    >
                                                        5%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(10)}
                                                    >
                                                        10%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(20)}
                                                    >
                                                        20%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(40)}
                                                    >
                                                        40%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(80)}
                                                    >
                                                        80%
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaCrosshairs}
                                                  transform="translateX(1px)"
                                                  style={{color: "whitesmoke", fontSize: '24px'}}/>
                                            <InputGroup size='lg' w={"178px"} mr={4}>
                                                <NumberInput focusBorderColor={"blackAlpha.500"} size='lg'
                                                             defaultValue={50} precision={0}
                                                             step={5}
                                                             min={10}
                                                             max={90}
                                                             onChange={(valueString) => setBuyOrderAtTokenPricePercent(removePercent(valueString))}
                                                             value={addPercent(buyOrderAtTokenPricePercent)}>
                                                    <NumberInputField color={"whitesmoke"}/>
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper color={"whitesmoke"}/>
                                                        <NumberDecrementStepper color={"whitesmoke"}/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <InputRightAddon ml={2} mr={"1px"} borderRadius={'base'}
                                                                 children="price"/>
                                            </InputGroup>

                                            <Icon as={GiTimeBomb}
                                                  ml={0}
                                                  style={{color: "whitesmoke", fontSize: '28px'}}/>
                                            <InputGroup size='lg' w={"163px"}>
                                                <NumberInput
                                                    focusBorderColor={"blackAlpha.500"}
                                                    size="lg"
                                                    defaultValue={5}
                                                    precision={0}
                                                    step={1}
                                                    min={1}
                                                    max={360}
                                                    onChange={(valueString) => setBuyOrderValidMinutes(valueString)}
                                                    value={buyOrderValidMinutes}
                                                >
                                                    <NumberInputField color={"whitesmoke"}/>
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper color={"whitesmoke"}/>
                                                        <NumberDecrementStepper color={"whitesmoke"}/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <InputRightAddon ml={2} mr={"1px"} borderRadius={'base'}
                                                                 children="mins"/>
                                            </InputGroup>
                                        </HStack>
                                        <HStack>
                                            <Spinner style={{opacity: isEstimateLoading ? 1 : 0}} ml={-2} mb={1.5}
                                                     color={'whitesmoke'} speed={'1.0s'} size='md'/>
                                            <Stat color={'whitesmoke'} w={"120px"}>
                                                <StatLabel>Estimated Total</StatLabel>
                                                <StatNumber>{txEstimate?.grandTotalEstimateEth ? txEstimate?.grandTotalEstimateEth.toFixed(3) : ethBuyAmountDecimal.toFixed(3)} ETH</StatNumber>
                                                <StatHelpText>({txEstimate?.totalSizeEstimateEth} + {txEstimate?.totalFeeEstimateEth?.toFixed(3)} gas)</StatHelpText>
                                            </Stat>
                                            <LongPressButton buttonTransform={"translateX(10px)"} buttonWidth={"260px"}
                                                             buttonHeight={"80px"} buttonText={'Send'}
                                                             isLoading={executionLoading}
                                                             isInvalid={!isAddressValid || !swapContractAddress || isEstimateLoading}
                                                             onLongPress={handleSendOrder}/>
                                        </HStack>
                                    </VStack>
                                </TabPanel>
                                <TabPanel m={0} p={0}>
                                    <VStack mt={1}>
                                        <HStack>
                                            <Icon as={LiaFileContractSolid}
                                                  style={{color: "whitesmoke", fontSize: '26px'}}/>
                                            <Input focusBorderColor={"blackAlpha.500"} fontSize={"16px"}
                                                   size="lg"
                                                   variant='outline' w={"400px"} color="whitesmoke"
                                                   placeholder='0x977c5fcf7a552d38adcde4f41025956855497c6d'
                                                   value={swapContractAddress}
                                                   isInvalid={!isAddressValid}
                                                   errorBorderColor='crimson'
                                                   onChange={(e) => handleContractChange(e.target.value)}
                                            />
                                        </HStack>
                                        <HStack>
                                            <Icon as={RiCoinsFill}
                                                  style={{color: "whitesmoke", fontSize: '26px'}}/>
                                            <InputGroup size='lg' w={"400px"}>
                                                <NumberInput focusBorderColor={"blackAlpha.500"}
                                                             defaultValue={100} precision={0}
                                                             max={100}
                                                             min={10}
                                                             step={10}
                                                             w={"400px"}
                                                             onChange={(valueString) => handleTokenSellPercentChange(removePercent(valueString))}
                                                             value={addPercent(tokenSellPercent)}>
                                                    <NumberInputField textAlign={'center'} color={"whitesmoke"}/>
                                                    <NumberInputStepper w={"50px"}>
                                                        <NumberIncrementStepper pl={"2px"} color={"whitesmoke"}/>
                                                        <NumberDecrementStepper pl={"2px"} color={"whitesmoke"}/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <InputRightAddon ml={2} px={"15px"} mr={"1px"} borderRadius={'base'}
                                                                 children="TKN"/>
                                            </InputGroup>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaGasPump}
                                                  transform="translateX(5px)"
                                                  style={{color: "whitesmoke", fontSize: '22px'}}/>
                                            <Menu>
                                                <MenuButton size="lg" pl={"0px"} ml={"5px"} pr={"4px"}
                                                            variant={'outline'}
                                                            color={'whitesmoke'} w={"400px"} as={Button}
                                                    // colorScheme={"blackAlpha"}
                                                    // borderColor={"blackAlpha.500"}
                                                            rightIcon={<IoMdArrowRoundDown
                                                                color={'whitesmoke'}/>}
                                                >
                                                    {getGasOption(gasOption)}
                                                </MenuButton>
                                                <MenuList p={0} bg={'gray.800'} w={'400px'}>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("WITHIN_SIX_BLOCKS")}
                                                    >
                                                        ≤ 6 blocks
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("WITHIN_THREE_BLOCKS")}
                                                    >
                                                        ≤ 3 blocks
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleGasOptionChange("ASAP")}
                                                    >
                                                        ASAP
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <HStack>
                                            <Icon as={FaPersonFalling}
                                                  style={{color: "whitesmoke", fontSize: '24px'}}/>
                                            <Menu>
                                                <MenuButton size="lg" pl={"0px"} pr={"4px"}
                                                            variant={'outline'}
                                                            color='whitesmoke' w={"400px"} as={Button}
                                                    // colorScheme={"blackAlpha"}
                                                            rightIcon={<IoMdArrowRoundDown
                                                                color={'whitesmoke'}/>}>
                                                    {slippageOption}%
                                                </MenuButton>
                                                <MenuList p={0} bg={'gray.800'} w={'400px'}>
                                                    {/*<MenuItem*/}
                                                    {/*    textAlign="center"*/}
                                                    {/*    borderBottomColor={"blackAlpha.300"}*/}
                                                    {/*    borderBottomWidth={"1px"}*/}
                                                    {/*    bg={'transparent'}*/}
                                                    {/*    color={'whitesmoke'}*/}
                                                    {/*    onClick={() => setSlippageOption('SlippageGradient™')}*/}
                                                    {/*>*/}
                                                    {/*    SlippageGradient™*/}
                                                    {/*</MenuItem>*/}
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(5)}
                                                    >
                                                        5%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(10)}
                                                    >
                                                        10%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(20)}
                                                    >
                                                        20%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(40)}
                                                    >
                                                        40%
                                                    </MenuItem>
                                                    <MenuItem
                                                        textAlign="center"
                                                        borderBottomColor={"blackAlpha.300"}
                                                        borderBottomWidth={"1px"}
                                                        bg={'transparent'}
                                                        color={'whitesmoke'}
                                                        onClick={() => handleSlippageOptionChange(80)}
                                                    >
                                                        80%
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                        <HStack>
                                            <Spinner style={{opacity: isEstimateLoading ? 1 : 0}} ml={-2} mb={1.5}
                                                     color={'whitesmoke'} speed={'1.0s'} size='md'/>
                                            <Stat color={'whitesmoke'} w={"120px"}>
                                                <StatLabel>Estimated Output</StatLabel>
                                                <StatNumber>{txEstimate?.grandTotalEstimateEth ? txEstimate?.grandTotalEstimateEth.toFixed(3) : 'N/A'} ETH</StatNumber>
                                                <StatHelpText>({txEstimate?.totalSizeEstimateEth} - {txEstimate?.totalFeeEstimateEth?.toFixed(3)} gas)</StatHelpText>
                                            </Stat>
                                            <LongPressButton buttonTransform={"translateX(10px)"} buttonWidth={"260px"}
                                                             buttonHeight={"80px"} buttonText={'Send'}
                                                             isLoading={executionLoading}
                                                             isInvalid={!isAddressValid || !swapContractAddress || isEstimateLoading}
                                                             onLongPress={handleExecuteSwap}/>
                                        </HStack>
                                    </VStack>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem h={"100%"} w={"100%"}>
                <Card bg={'gray.600'}
                      shadow={'inner'}
                      h={"100%"} w={"100%"}>
                    <CardBody pl={2} pr={2} pt={1}>
                        <Center>
                            <Grid h={"100%"}>
                                <GridItem>
                                    <VStack mt={1}>
                                        <Heading mt={0} pt={0} as='h4' size='md'
                                                 color={"whitesmoke"}>Status
                                        </Heading>
                                        <Tag
                                            hidden={!["READY", "BUILDING", "CREATED", "MIRRORING", "PENDING", "INCLUDED", "FAILED"].includes(txStatus)}
                                            size='lg' color='whiteSmoke'
                                            borderRadius='full' w={"100%"}>
                                            <Icon as={FaCheckCircle}
                                                  style={{color: "whitesmoke", fontSize: '18px'}}></Icon>
                                            <TagLabel ml={"1"} fontSize={"12px"}>Ready</TagLabel>
                                        </Tag>
                                        <Tag
                                            hidden={!["BUILDING", "CREATED", "MIRRORING", "PENDING", "INCLUDED", "FAILED"].includes(txStatus)}
                                            size='lg' color='whiteSmoke'
                                            borderRadius='full' w={"100%"}>
                                            {txStatus === "BUILDING"
                                                ? <Spinner speed={'0.5s'} size='xs'/>
                                                : <Icon as={FaCheckCircle}
                                                        style={{color: "whitesmoke", fontSize: '18px'}}></Icon>}
                                            <TagLabel ml={"1"} fontSize={"12px"}>Building</TagLabel>
                                        </Tag>
                                        <Tag
                                            hidden={!["CREATED", "MIRRORING", "PENDING", "INCLUDED", "FAILED"].includes(txStatus)}
                                            size='lg' color='whiteSmoke'
                                            borderRadius='full' w={"100%"}>
                                            {txStatus === "Created"
                                                ? <Spinner speed={'0.5s'} size='xs'/>
                                                : <Icon as={FaCheckCircle}
                                                        style={{color: "whitesmoke", fontSize: '18px'}}></Icon>}
                                            <TagLabel ml={"1"} fontSize={"12px"}>Created</TagLabel>
                                        </Tag>
                                        <Tag
                                            hidden={!["MIRRORING", "PENDING", "INCLUDED", "FAILED"].includes(txStatus)}
                                            size='lg' color='whiteSmoke'
                                            borderRadius='full' w={"100%"}>
                                            {txStatus === "MIRRORING"
                                                ? <Spinner speed={'0.5s'} size='xs'/>
                                                : <Icon as={FaCheckCircle}
                                                        style={{color: "whitesmoke", fontSize: '18px'}}></Icon>}
                                            <TagLabel ml={"1"} fontSize={"12px"}>Mirroring</TagLabel>
                                        </Tag>
                                        <Tag hidden={!["PENDING", "INCLUDED", "FAILED"].includes(txStatus)}
                                             size='lg' color='whiteSmoke'
                                             borderRadius='full' w={"100%"}>
                                            {txStatus === "PENDING"
                                                ? <Spinner speed={'0.5s'} size='xs'/>
                                                : <Icon as={FaCheckCircle}
                                                        style={{color: "whitesmoke", fontSize: '18px'}}></Icon>}
                                            <TagLabel ml={"1"} fontSize={"12px"}>Pending</TagLabel>
                                        </Tag>
                                        <Tag hidden={!["INCLUDED", "FAILED"].includes(txStatus)} size='lg'
                                             color='whiteSmoke'
                                             borderRadius='full' w={"100%"}>
                                            {txStatus === "INCLUDED"
                                                ?
                                                <Icon as={FaCheckCircle}
                                                      style={{color: "whitesmoke", fontSize: '18px'}}></Icon>
                                                :
                                                <Icon as={BiSolidError}
                                                      style={{color: "whitesmoke", fontSize: '18px'}}></Icon>}
                                            {txStatus === "INCLUDED"
                                                ? <TagLabel ml={"1"} fontSize={"12px"}>
                                                    <Link href={`https://etherscan.io/tx/${txHash}`} isExternal>
                                                        Included <ExternalLinkIcon mx='2px'/>
                                                    </Link>
                                                </TagLabel>
                                                : <TagLabel ml={"1"} fontSize={"12px"}>Failed</TagLabel>}
                                        </Tag>
                                    </VStack>
                                </GridItem>
                            </Grid>
                        </Center>
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
    );
}

export default SwapPanel;