import {TokenData} from "../../../../interfaces/datatypes";

export const mergeData = (newUpdates: TokenData[], currentList: TokenData[]) => {
    let updates = newUpdates.map(update => ({
        ...update,
    }));
    const existingUpdatesMap = new Map(currentList.map((td) => [td.contractAddress, td]));
    updates.forEach((newData: TokenData) => {
        existingUpdatesMap.set(newData.contractAddress, newData); // Overwrites if already exists
    });
    // Merge the updates into a new array
    return Array.from(existingUpdatesMap.values());
};

export const sortData = (tokenFeedSortColumn: string, tokenData: TokenData[]) => {
    // Filter out rows with null or undefined values in tokenAgeBlocks or activityTimeBlocks when sorting by age or activity
    const filteredTokenData = tokenData.filter((token) => {
        return token.tokenAgeBlocks != null && token.activityTimeBlocks != null;
    });

    // Create a copy of the filtered data
    const tokenDataCopy = [...filteredTokenData];

    // Sort the filtered data copy
    tokenDataCopy.sort((a: TokenData, b: TokenData) => {
        if (tokenFeedSortColumn === 'score') {
            return b.totalScore - a.totalScore;
        } else if (tokenFeedSortColumn === 'ticker') {
            return a.tokenSymbol.localeCompare(b.tokenSymbol);
        } else if (tokenFeedSortColumn === 'age') {
            return (a.tokenAgeBlocks as number) - (b.tokenAgeBlocks as number); // Using type assertion
        } else if (tokenFeedSortColumn === 'activity') {
            return (a.activityTimeBlocks as number) - (b.activityTimeBlocks as number);
        } else if (tokenFeedSortColumn === 'traders') {
            const aTraders = (a.topNamedCount && a.topAnonCount)
                ? a.topNamedCount + a.topAnonCount
                : a.topNamedCount
                    ? a.topNamedCount
                    : a.topAnonCount
                        ? a.topAnonCount
                        : 0;
            const bTraders = (b.topNamedCount && b.topAnonCount)
                ? b.topNamedCount + b.topAnonCount
                : b.topNamedCount
                    ? b.topNamedCount
                    : b.topAnonCount
                        ? b.topAnonCount
                        : 0;
            return (bTraders as number) - (aTraders as number);
        } else if (tokenFeedSortColumn === 'mcap') {
            const aMcap = (a.marketCapValue)
                ? a.marketCapValue
                : 0;
            const bMcap = (b.marketCapValue)
                ? b.marketCapValue
                : 0;
            return (bMcap as number) - (aMcap as number);
        } else if (tokenFeedSortColumn === 'delta') {
            const aDelta = (a.previousTotalScore)
                ? a.totalScore - a.previousTotalScore
                : null;
            const bDelta = (b.previousTotalScore)
                ? b.totalScore - b.previousTotalScore
                : null;
            if (aDelta === null && bDelta !== null) return 1;
            if (aDelta !== null && bDelta === null) return -1;
            if (aDelta === null && bDelta === null) return 0;
            return (bDelta as number) - (aDelta as number);
        }
        return 0;
    });
    return tokenDataCopy;
};
