import React, {useEffect, useState} from 'react';
import {Button, Flex, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import {useLoginMutation} from '../../services/loginService';
import {useDispatch} from "react-redux";
import {setAuthentication} from "../../reducers/sessionSlice";

function Login() {
    const dispatch = useDispatch();
    const [login, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useLoginMutation();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [show, setShow] = useState<boolean>(false)

    const handleClick = () => setShow(!show)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        login({username, password});
    };

    useEffect(() => {
        if (isSuccess && data) {
            dispatch(setAuthentication({
                username: data.username,
                apiKey: data.apiKey,
                walletAddress: data.walletAddress,
                walletBalance: data.walletBalance,
                rr: data.rr,
                rank: data.rank,
            }));
        }
    }, [isSuccess, data, dispatch]);

    useEffect(() => {
        if (isError) {
            console.error('Login error:', error);
        }
    }, [isError, error]);

    return (
        <Flex
            height="100vh"
            alignItems="center"
            justifyContent="center"
        >
            <Flex direction="column" p={12} rounded={6} width={{
                base: "100%",
                sm: "100%",
                md: "80%",
                lg: "60%",
                xl: "40%",
                "2xl": "20%",
            }}>
                <Input
                    borderColor="brand.cyan.300"
                    focusBorderColor="brand.cyan.700"
                    size='lg'
                    placeholder='username'
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <InputGroup size='lg' mt={2} colorScheme="brand.orange">
                    <Input
                        borderColor="brand.cyan.300"
                        focusBorderColor="brand.cyan.700"
                        onChange={(e) => setPassword(e.target.value)}
                        pr='4.5rem'
                        type={show ? 'text' : 'password'}
                        placeholder='password'
                    />
                    <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={handleClick} colorScheme="brand.cyan">
                            {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <Button
                    mt={2}
                    colorScheme="brand.cyan"
                    onClick={handleSubmit}
                    rightIcon={<ArrowForwardIcon/>}
                    disabled={isLoading}
                    variant='solid'>
                    Login
                </Button>
            </Flex>
        </Flex>
    );
}

export default Login;
