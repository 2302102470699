import {FrequentTokenSymbol, LargeLiquidityAdd, LargeSwap, PreappSnipe} from "../../../../interfaces/datatypes";
import React, {useState} from "react";
import {Card, CardBody, Grid, GridItem, Link, Tag, Text} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {FaLeftLong, FaRightLong} from "react-icons/fa6";

interface AnomalyFeedItemProps {
    anomalyData: LargeSwap | LargeLiquidityAdd | FrequentTokenSymbol | PreappSnipe;
    itemType: string;
}

const AnomalyFeedItem: React.FC<AnomalyFeedItemProps> = ({anomalyData, itemType}) => {

    const [styles, setStyles] = useState({
        bg: 'gray.600',
        shadow: 'dark-lg',
    });

    const handleMouseOver = () => {
        setStyles({
            bg: 'gray.600',
            shadow: 'sm',
        });
    };

    const handleMouseOut = () => {
        setStyles({
            bg: 'gray.600',
            shadow: 'dark-lg',
        });
    };

    const renderSwapItem = (largeSwapData: LargeSwap) => {
        return (
            <>
                <Grid gap={1} alignItems="left" gridTemplateColumns={'1fr 2fr 3fr 2fr'} h={"100%"} w={"100%"}>
                    <GridItem>
                        <Link href={`https://etherscan.io/tx/${largeSwapData.txHash}`} isExternal>
                            <Tag bg={"BUY" === largeSwapData.action ? "brand.green.400" : "brand.red.400"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {largeSwapData.action}
                                </Text>
                            </Tag>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Link href={`https://dexscreener.com/ethereum/${largeSwapData.tokenAddress}`} isExternal>
                            <Tag bg={"brand.blue.300"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {largeSwapData.tokenSymbol.length > 10 ? `${largeSwapData.tokenSymbol.substring(0, 10)}...` : largeSwapData.tokenSymbol}
                                </Text>
                            </Tag>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Link href={`https://etherscan.io/address/${largeSwapData.address}`} isExternal>
                            <Tag bg={"brand.pink.300"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {largeSwapData.ensName
                                        ? (largeSwapData.ensName.length > 15 ? `${largeSwapData.ensName.substring(0, 15)}...` : largeSwapData.ensName)
                                        : `${largeSwapData.address.substring(0, 6)}...`}
                                </Text>
                            </Tag>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.orange.600"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {largeSwapData.rr ? `${largeSwapData.rr} RR` : 'N/A'}
                            </Text>
                        </Tag>
                    </GridItem>
                </Grid>
                <Grid mt={2} gap={1} alignItems="left" gridTemplateColumns={'4fr 1fr 5fr 3.5fr'} h={"100%"}
                      w={"100%"}>
                    {"BUY" === largeSwapData.action &&
                        <>
                            <GridItem>
                                <Tag bg={"brand.green.400"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {largeSwapData.tokenAmountString} Token
                                    </Text>
                                </Tag>
                            </GridItem>
                            <GridItem>
                                <Tag bg={"gray.700"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Icon as={FaLeftLong} w={4} h={4} color="whitesmoke"/>
                                </Tag>
                            </GridItem>
                            <GridItem>
                                <Tag bg={"brand.red.400"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {largeSwapData.wethAmountDecimal} ETH
                                    </Text>
                                </Tag>
                            </GridItem>
                        </>
                    }
                    {"SELL" === largeSwapData.action &&
                        <>
                            <GridItem>
                                <Tag bg={"brand.red.400"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {largeSwapData.tokenAmountString} Token
                                    </Text>
                                </Tag>
                            </GridItem>
                            <GridItem>
                                <Tag bg={"gray.700"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Icon as={FaRightLong} w={4} h={4} color="whitesmoke"/>
                                </Tag>
                            </GridItem>
                            <GridItem>
                                <Tag bg={"brand.green.400"}
                                     color={"whitesmoke"}
                                     size={"sm"}
                                     w={"100%"}
                                     display="flex"
                                     justifyContent="center"
                                     alignItems="center">
                                    <Text as={"b"}>
                                        {largeSwapData.wethAmountDecimal} ETH
                                    </Text>
                                </Tag>
                            </GridItem>
                        </>
                    }
                    <GridItem>
                        <Tag bg={"brand.blue.900"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {/*{formatDateToCustom(new Date(largeLiqData.liquidityAddedDate))}*/}
                                {largeSwapData.blockAge}
                            </Text>
                        </Tag>
                    </GridItem>
                </Grid>
            </>
        );
    };

    const renderLiquidityAddItem = (largeLiqData: LargeLiquidityAdd) => {
        return (
            <>
                <Grid gap={1} alignItems="left" gridTemplateColumns={'2fr 6fr 6fr 5fr'} h={"100%"} w={"100%"}>
                    <GridItem>
                        <Link href={`https://etherscan.io/tx/${largeLiqData.liquidityAddedHash}`} isExternal>
                            <Tag bg={"purple.500"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    ADD
                                </Text>
                            </Tag>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Link href={`https://dexscreener.com/ethereum/${largeLiqData.contractAddress}`} isExternal>
                            <Tag bg={"brand.blue.300"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {largeLiqData.tokenSymbol.length > 10 ? `${largeLiqData.tokenSymbol.substring(0, 10)}...` : largeLiqData.tokenSymbol}
                                </Text>
                            </Tag>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.green.700"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {largeLiqData.ethAddedDecimal} ETH
                            </Text>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.blue.900"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {/*{formatDateToCustom(new Date(largeLiqData.liquidityAddedDate))}*/}
                                {largeLiqData.blockAge}
                            </Text>
                        </Tag>
                    </GridItem>
                </Grid>
            </>
        );
    };

    const renderFrequentTokenSymbolItem = (frequentTokenSymbolData: FrequentTokenSymbol) => {
        return (
            <>
                <Grid gap={1} alignItems="left" gridTemplateColumns={'1fr 5fr 2fr'} h={"100%"} w={"100%"}>
                    <GridItem>
                        <Tag bg={"brand.greyscale.800"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {frequentTokenSymbolData.count}X
                            </Text>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.blue.300"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {frequentTokenSymbolData.tokenSymbol.length > 20 ? `${frequentTokenSymbolData.tokenSymbol.substring(0, 20)}...` : frequentTokenSymbolData.tokenSymbol}
                            </Text>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.blue.900"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {frequentTokenSymbolData.blockAge}
                            </Text>
                        </Tag>
                    </GridItem>
                </Grid>
            </>
        );
    };

    const renderPreappSnipeItem = (preappSnipeData: PreappSnipe) => {
        return (
            <>
                <Grid gap={1} alignItems="left" gridTemplateColumns={'1fr 5fr 2fr'} h={"100%"} w={"100%"}>
                    <GridItem>
                        <Tag bg={"brand.greyscale.100"}
                             color={"brand.greyscale.800"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             px={0}
                             alignItems="center">
                            <Text as={"b"}>
                                HYPE
                            </Text>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Link href={`https://dexscreener.com/ethereum/${preappSnipeData.contractAddress}`} isExternal>
                            <Tag bg={"brand.blue.300"}
                                 color={"whitesmoke"}
                                 size={"sm"}
                                 w={"100%"}
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center">
                                <Text as={"b"}>
                                    {preappSnipeData.tokenSymbol.length > 10 ? `${preappSnipeData.tokenSymbol.substring(0, 10)}...` : preappSnipeData.tokenSymbol}
                                </Text>
                            </Tag>
                        </Link>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.blue.900"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {preappSnipeData.blockAge}
                            </Text>
                        </Tag>
                    </GridItem>
                </Grid>
                <Grid mt={2} gap={1} alignItems="left" gridTemplateColumns={'1fr 1fr'} h={"100%"} w={"100%"}>
                    <GridItem>
                        <Tag bg={"brand.pink.700"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {preappSnipeData.preapprovalCount} pre-approvals
                            </Text>
                        </Tag>
                    </GridItem>
                    <GridItem>
                        <Tag bg={"brand.purple.700"}
                             color={"whitesmoke"}
                             size={"sm"}
                             w={"100%"}
                             display="flex"
                             justifyContent="center"
                             alignItems="center">
                            <Text as={"b"}>
                                {preappSnipeData.snipeCount} snipes
                            </Text>
                        </Tag>
                    </GridItem>
                </Grid>
            </>
        );
    };

    return (
        <Card m={1} {...styles} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <CardBody p={2}>
                {itemType === 'largeSwap' && renderSwapItem(anomalyData as LargeSwap)}
                {itemType === 'largeLiquidityAdd' && renderLiquidityAddItem(anomalyData as LargeLiquidityAdd)}
                {itemType === 'frequentTokenSymbol' && renderFrequentTokenSymbolItem(anomalyData as FrequentTokenSymbol)}
                {itemType === 'preappSnipe' && renderPreappSnipeItem(anomalyData as PreappSnipe)}
            </CardBody>
        </Card>
    );
}

export default AnomalyFeedItem;
