import React, {useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Heading,
    HStack,
    Input,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {LiaFileContractSolid} from "react-icons/lia";
import {SimulateRequest} from "../../../../interfaces/datatypes";
import {useSelector} from "react-redux";
import {getSelectedTokenAddress} from "../../../../reducers/timelineSlice";
import {useSimulateMutation, useSimulateScrapeMutation} from "../../../../services/swapService";


function SimulatePanel() {

    const contractAddress = useSelector(getSelectedTokenAddress);

    const [simulateScrape, {
        data: simulationScrapeResults,
        isLoading: isSimulationScrapeLoading,
    }] = useSimulateScrapeMutation();

    const [simulate, {
        data: simulationResults,
        isLoading: isSimulationLoading,
    }] = useSimulateMutation();

    const [isAddressValid, setIsAddressValid] = useState(true);
    const [contractAddressField, setContractAddressField] = useState<string | undefined>(contractAddress);

    const runAllSimulations = () => {
        if (contractAddressField && isAddressValid) {
            const request: SimulateRequest = {
                contractAddress: contractAddressField,
            };

            simulate(request);
            simulateScrape(request);
        }
    };

    const handleContractChange = (newAddress: string) => {
        setContractAddressField(newAddress);
        if (!newAddress) {
            setIsAddressValid(true)
        } else {
            const ethAddressRegex = /^0x[a-fA-F0-9]{40}$/;
            setIsAddressValid(ethAddressRegex.test(newAddress));
        }
    };

    return (
        <Card bg={'gray.600'}
              shadow={'inner'}
              h={"100%"}>
            <CardBody pl={2} pr={2} pt={1}>
                <VStack mt={1}>
                    <Heading mt={0} pt={0} as='h4' size='md'
                             color={"whitesmoke"}>Simulate</Heading>
                    <HStack mr={10}>
                        <Icon as={LiaFileContractSolid}
                              style={{color: "whitesmoke", fontSize: '26px'}}/>
                        <Input focusBorderColor={"blackAlpha.500"} fontSize={"16px"}
                               size="lg"
                               variant='outline' w={"400px"} color="whitesmoke"
                               placeholder='0x977c5fcf7a552d38adcde4f41025956855497c6d'
                               isInvalid={!isAddressValid}
                               value={contractAddressField}
                               errorBorderColor='crimson'
                               onChange={(e) => handleContractChange(e.target.value)}
                        />
                    </HStack>
                    <Button
                        ml={"-6px"}
                        bg={"blackAlpha.600"}
                        color={"whitesmoke"}
                        colorScheme={"whiteAlpha"}
                        size='lg'
                        h={"80px"}
                        w={"400px"}
                        isDisabled={isSimulationLoading || isSimulationScrapeLoading || !isAddressValid || !contractAddressField}
                        isLoading={isSimulationLoading || isSimulationScrapeLoading}
                        onClick={runAllSimulations}
                    >
                        Run
                    </Button>
                    <TableContainer>
                        <Table size='sm' variant='striped' colorScheme={'blackAlpha'}>
                            <Thead>
                                <Tr>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'}>Source</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'}>Safe</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'}>Exploits</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'} isNumeric>Buy
                                        Tax</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'} isNumeric>Sell
                                        Tax</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'} isNumeric>Buy
                                        Limit</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'} isNumeric>Sell
                                        Limit</Th>
                                    <Th fontSize={"10px"} color={'whiteAlpha.600'} isNumeric>Wallet
                                        Limit</Th>
                                    {/*<Th fontSize={"10px"} color={'whiteAlpha.600'} isNumeric>Cooldown</Th>*/}
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'}>Marflow
                                        Matrix™
                                        {isSimulationLoading &&
                                            <Spinner speed={'1.0s'} pl={2} pt={2}
                                                     size='xs'/>}
                                    </Td>
                                    <Td fontSize={"12px"}
                                        color={
                                            simulationResults?.isSafe === null || simulationResults?.isSafe === undefined
                                                ? 'whitesmoke'
                                                : simulationResults?.isSafe
                                                    ? 'green.500'
                                                    : 'red.500'
                                        }
                                    >
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.isSafe === null || simulationResults?.isSafe === undefined ? "N/A" : (simulationResults?.isSafe ? "Yes" : "No")}
                                    </Td>
                                    <Td fontSize={"12px"}
                                        color={
                                            simulationResults?.hasExploits === null || simulationResults?.hasExploits === undefined
                                                ? 'whitesmoke'
                                                : simulationResults?.hasExploits
                                                    ? 'red.500'
                                                    : 'green.500'
                                        }
                                    >
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.hasExploits === null || simulationResults?.hasExploits === undefined ? "N/A" : (simulationResults?.hasExploits ? "Yes" : "No")}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.buyTaxPercent || simulationResults?.buyTaxPercent === 0 ? `${simulationResults.buyTaxPercent.toFixed(2)}%` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.sellTaxPercent || simulationResults?.sellTaxPercent === 0 ? `${simulationResults.sellTaxPercent.toFixed(2)}%` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.maxBuyLimitEthDecimal || simulationResults?.maxBuyLimitEthDecimal === 0.0 ? `${simulationResults.maxBuyLimitEthDecimal.toFixed(2)} ETH` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.maxSellLimitEthDecimal || simulationResults?.maxSellLimitEthDecimal === 0.0 ? `${simulationResults.maxSellLimitEthDecimal.toFixed(2)} ETH` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationLoading || !simulationResults ? "" : simulationResults?.maxWalletLimitEthDecimal || simulationResults?.maxWalletLimitEthDecimal === 0.0 ? `${simulationResults.maxWalletLimitEthDecimal.toFixed(2)} ETH` : "N/A"}
                                    </Td>
                                    {/*<Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>5</Td>*/}
                                </Tr>
                                <Tr>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'}>
                                        honeypot.is
                                        {isSimulationScrapeLoading &&
                                            <Spinner speed={'1.0s'} pl={2} pt={2}
                                                     size='xs'/>}
                                    </Td>
                                    <Td fontSize={"12px"}
                                        color={
                                            simulationScrapeResults?.isSafe === null || simulationScrapeResults?.isSafe === undefined
                                                ? 'whitesmoke'
                                                : simulationScrapeResults?.isSafe
                                                    ? 'green.500'
                                                    : 'red.500'
                                        }
                                    >
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.isSafe === null || simulationScrapeResults?.isSafe === undefined ? "N/A" : (simulationScrapeResults?.isSafe ? "Yes" : "No")}
                                    </Td>
                                    <Td fontSize={"12px"}
                                        color={
                                            simulationScrapeResults?.hasExploits === null || simulationScrapeResults?.hasExploits === undefined
                                                ? 'whitesmoke'
                                                : simulationScrapeResults?.hasExploits
                                                    ? 'red.500'
                                                    : 'green.500'
                                        }
                                    >
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.hasExploits === null || simulationScrapeResults?.hasExploits === undefined ? "N/A" : (simulationScrapeResults?.hasExploits ? "Yes" : "No")}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.buyTaxPercent || simulationScrapeResults?.buyTaxPercent === 0 ? `${simulationScrapeResults.buyTaxPercent}%` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.sellTaxPercent || simulationScrapeResults?.sellTaxPercent === 0 ? `${simulationScrapeResults.sellTaxPercent}%` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.maxBuyLimitEthDecimal || simulationScrapeResults?.maxBuyLimitEthDecimal === 0.0 ? `${simulationScrapeResults.maxBuyLimitEthDecimal.toFixed(2)} ETH` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.maxSellLimitEthDecimal || simulationScrapeResults?.maxSellLimitEthDecimal === 0.0 ? `${simulationScrapeResults.maxSellLimitEthDecimal.toFixed(2)} ETH` : "N/A"}
                                    </Td>
                                    <Td fontSize={"12px"} color={'whiteAlpha.800'} isNumeric>
                                        {isSimulationScrapeLoading || !simulationScrapeResults ? "" : simulationScrapeResults?.maxWalletLimitEthDecimal || simulationScrapeResults?.maxWalletLimitEthDecimal === 0.0 ? `${simulationScrapeResults.maxWalletLimitEthDecimal.toFixed(2)} ETH` : "N/A"}
                                    </Td>
                                    {/*<Td fontSize={"12px"} color={'whiteAlpha.800'}*/}
                                    {/*    isNumeric>N/A</Td>*/}
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
            </CardBody>
        </Card>
    );
}

export default SimulatePanel;