import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {FavoritesRequest, LeaderboardRequest, LeaderboardResponse} from "../interfaces/datatypes";
import {RootState} from "../reducers/store";

export const leaderboardService = createApi({
    reducerPath: 'leaderboardService',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.marflow.live/v1/',
        prepareHeaders: (headers, {getState}) => {
            const state = getState() as RootState;
            const sessionData = state.session.sessionData;
            if (sessionData?.apiKey && sessionData?.username) {
                headers.set('X-API-KEY', sessionData.apiKey);
                headers.set('username', sessionData.username);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        leaderboard: builder.query<LeaderboardResponse, LeaderboardRequest>({
            query: (request) => ({
                url: 'leaderboard',
                method: 'POST',
                body: request,
            }),
        }),
        favorites: builder.mutation<void, FavoritesRequest>({
            query: (request) => ({
                url: 'favorites',
                method: 'POST',
                body: request,
            }),
        }),
    }),
});

export const {
    useLeaderboardQuery,
    useFavoritesMutation
} = leaderboardService;